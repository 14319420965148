import { FilterFormComponentName } from '@/components/filter-form/components';
import { FieldName } from '@/store/events/filters/fields';

const DefaultClearableFields = [FieldName.Page, FieldName.Id];

export const schema = {
  module: 'sessions',
  action: 'sessions/get',
  fields: [getStatusFieldData(), getMobileFieldData(), getNameInFieldData()]
};

function getNameInFieldData() {
  return {
    name: 'user_name_in',
    control: {
      i18nLabel: ['login'],
      component: [
        FilterFormComponentName.Select,
        {
          itemsPath: '$store.state.users.items',
          multiple: true,
          clearFieldsOnChange: DefaultClearableFields,
          computeItem: (v) => ({ ...v, value: v.name, label: v.name })
        }
      ]
    }
  };
}

function getStatusFieldData() {
  return {
    name: 'status',
    control: {
      i18nLabel: 'status',
      component: [
        FilterFormComponentName.Select,
        {
          items: [
            { value: '', i18nLabel: 'all' },
            { value: 'online', i18nLabel: 'status_online' },
            { value: 'offline', i18nLabel: 'status_offline' },
            { value: 'blocked', i18nLabel: 'status_blocked' }
          ],
          clearFieldsOnChange: DefaultClearableFields
        }
      ]
    }
  };
}

function getMobileFieldData() {
  return {
    name: 'mobile',
    control: {
      i18nLabel: 'mobile',
      component: [
        FilterFormComponentName.Select,
        {
          items: [
            { value: undefined, i18nLabel: 'all' },
            { value: true, i18nLabel: 'only_mobile' },
            { value: false, i18nLabel: 'only_web' }
          ],
          clearFieldsOnChange: DefaultClearableFields
        }
      ]
    }
  };
}
