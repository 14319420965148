import { components, operations } from '@/definitions/openapi';
import { BaseItemsStateModule } from '@/definitions/base.items.state.module';
import { Module, RegisterOptions } from 'vuex-class-modules';
import { EmptyFilter, EmptyItem } from '@/store/areas/records/items';
import store from '../../index';
import _ from '@/apps/common/lodash';
import { schema } from './filter.schema';

export type IAreaRecord = components['schemas']['AreaTriggerRecord'];
export type IAreaRecordFilter = operations['area_trigger_records_list']['parameters']['query'];

@Module({ generateMutationSetters: true })
export class AreaRecordsModule extends BaseItemsStateModule<IAreaRecord, IAreaRecordFilter> {
  name = 'area-trigger-records';
  routeName = 'areaRecords';

  constructor(options: RegisterOptions) {
    super(options);

    this.filter.schema = schema;
    this.filter.empty = _.cloneDeep(EmptyFilter);
    this.filter.current = _.cloneDeep(EmptyFilter);
    this.item.empty = _.cloneDeep(EmptyItem);
  }

  async dispatchImplementation(action: string, payload: any): Promise<any> {
    return store.dispatch(action, payload);
  }
}
