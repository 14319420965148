<template>
  <el-form class="filter-form" :size="$vars.sizes.form" :label-width="$vars.forms.label_width" :label-position="$vars.forms.label_position">
    <slot></slot>
    <el-form-item class="filter-form__clear-button">
      <slot name="clear-button"></slot>
    </el-form-item>
  </el-form>
</template>

<script>
export default { name: 'FilterFormLayout' };
</script>

<style lang="stylus">
.filter-form {
  display: grid;
  grid-column-gap: 1.6rem;
  grid-template-columns: repeat(2, 1fr);

  &__clear-button {
    grid-column: 1 / 3;
  }
}
</style>
