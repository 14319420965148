import _ from '@/apps/common/lodash';
import cache from '@/apps/common/cache';
import * as commonFilters from '@/apps/common/filters';
import hasNoPermission from '@/apps/common/hasNoPermission';
import WZoom from 'vanilla-js-wheel-zoom/dist/wheel-zoom.min.js';

export const ExtendProtoPlugin = {
  installed: false,
  install: function (Vue: any, options: any) {
    if (ExtendProtoPlugin.installed) return;

    Vue.prototype.$wzoom = WZoom;

    cache.init({ ttl: 60, interval: 5, randomize: false });
    Vue.prototype.$cache = cache;

    let filters = Object.assign({}, commonFilters);
    Object.keys(filters).forEach((name) => Vue.filter(name, filters[name]));
    Vue.prototype.$filters = filters;

    Vue.prototype.$applyRuleMessages = applyRuleMessages;
    Vue.prototype.$hasNoPermission = hasNoPermission;
    Vue.prototype.$hasPermission = (v) => !hasNoPermission(v);
    Vue.prototype.$scrollToFirstError = scrollToFirstError;
    Vue.prototype.$isActiveItem = isActiveItem;

    ExtendProtoPlugin.installed = true;
  }
};

function applyRuleMessages(rulesTemplate: any) {
  const rules = _.cloneDeep(rulesTemplate);
  Object.keys(rules).forEach((name) => {
    let items = rules[name];
    items[0] && (items[0].message = this.$t(items[0].message));
    items[0] && (items[0].tmessage = this.$t(items[0].tmessage));
    items[1] && (items[1].message = this.$t(items[1].message));
    if (items[0] && items[0].instance === null) items[0].instance = () => this.instance;
    if (items[0] && items[0].formData === null) items[0].formData = () => this.formData;
  });
  return rules;
}

function scrollToFirstError() {
  const page = document.querySelector('.page-content'),
    element = document.querySelector('.is-error');

  if (page && element) {
    page.scrollTo(0, page.scrollTop + element.getBoundingClientRect().top);
  }
}

function isActiveItem(path: string, v: any) {
  return v.pathStartsFrom ? (path || '').indexOf(v.pathStartsFrom) === 0 : v.path === path;
}
