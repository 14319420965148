<template>
  <el-select @change="clearLinkedFields" :multiple-limit="multipleLimit" :placeholder="placeholder" v-model="model" multiple>
    <el-option v-for="item in items" :key="item.id" :label="item.shortName" :value="item.id">
      <el-tooltip :content="item.name" placement="top">
        <span v-text="item.shortName"></span>
      </el-tooltip>
    </el-option>
  </el-select>
</template>

<script>
import _ from '@/apps/common/lodash';
import { Component } from 'vue-property-decorator';
import BaseFormComponent from './component';

@Component({
  props: {
    itemsPath: { type: String, required: true },
    multipleLimit: { type: Number, default: 10 }
  }
})
export default class BaseFormComponentCameraGroups extends BaseFormComponent {
  get items() {
    return _.get(this, this.itemsPath).map(this.normalizeCameraGroup);
  }

  normalizeCameraGroup(item) {
    return { ...item, shortName: this.$filters.shortString(item.name) };
  }
}
</script>
