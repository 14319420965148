import { FilterFormComponentName } from '@/components/filter-form/components';
import { FieldName } from '@/store/events/filters/fields';

const DefaultClearableFields = [FieldName.Page, FieldName.Id];

export const schema = {
  module: 'blocklist_records',
  action: 'blocklist_records/get',
  fields: [
    {
      name: 'reason_contains',
      control: {
        i18nLabel: 'reason_contains',
        component: [
          FilterFormComponentName.Input,
          {
            clearFieldsOnChange: DefaultClearableFields
          }
        ]
      }
    },
    {
      name: 'uuid_contains',
      control: {
        i18nLabel: 'uuid_contains',
        component: [
          FilterFormComponentName.Input,
          {
            clearFieldsOnChange: DefaultClearableFields
          }
        ]
      }
    }
  ]
};
