import { createDecorator } from 'vue-class-component';
import _ from '../lodash';

export const DebounceTime = {
  Load: 750
};

export const Debounce = (value = DebounceTime.Load) =>
  createDecorator((options, key) => {
    const baseMethod = options.methods[key];
    const extendedMethod = _.debounce(baseMethod, value);
    options.methods[key] = extendedMethod;
  });
