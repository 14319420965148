























import { Component, Vue } from 'vue-property-decorator';

@Component({
  name: 'app-layout'
})
export default class AppLayout extends Vue {
  leaveTimeout = 0;
  dragover = false;

  get classes() {
    const { collapsed, parent } = this.$store.state.app.menu;
    return {
      'default-page': true,
      dragover: this.dragover,
      'disable-events': this.$store.state.window.disableEvents,
      'menu-collapsed': collapsed,
      'page-content--secondary': parent && parent.children
    };
  }

  get background() {
    if (!this.pageHasBackground) return null;
    const { themeBackground } = this.$store.getters;
    return { 'background-image': `url('${themeBackground}')` };
  }

  get pageHasBackground() {
    const pagesWithBackground = ['login', 'launcher'];
    return pagesWithBackground.includes(this.$route.name || '');
  }

  dragOverHandler(e) {
    clearTimeout(this.leaveTimeout);
    if (this.$store.state.app.fileHandler) this.dragover = true;
    return true;
  }

  dragLeaveHandler(e) {
    clearTimeout(this.leaveTimeout);
    this.leaveTimeout = setTimeout(() => {
      this.dragover = false;
    }, 100);
  }

  dropHandler(e) {
    this.dragover = false;
    const transferItems = e.dataTransfer.items;

    if (transferItems && transferItems.length) {
      const files = Array.prototype.slice
        .call(transferItems)
        .map((v) => v.kind === 'file' && v.getAsFile())
        .filter((v) => !!v);
      this.$store.state.app.fileHandler && this.$store.state.app.fileHandler(files);
    }
    return false;
  }
}
