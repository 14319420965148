<template>
  <el-dialog
    name="object-selector-dialog"
    ref="dialog"
    :title="$tf(`select_${state.objects}_object`)"
    :visible="true"
    class="object-dialog--container"
    @close="closeHandler"
  >
    <object-selector v-if="state.dialog.enabled && state.items" v-model="state.selectedIndex" class="full" :image="state.image" :items="state.items" />
    <el-button name="select-btn" type="primary" slot="footer" @click="selectHandler">
      {{ $tfo('select') }}
    </el-button>
  </el-dialog>
</template>

<script>
import Component from 'vue-class-component';
import ObjectSelector from './object.selector';

@Component({
  name: 'detect-dialog',
  components: { ObjectSelector }
})
export default class DetectDialog extends Component {
  get state() {
    return this.$store.state.detect;
  }

  changeHandler(v) {
    this.state.selectedIndex = v;
  }

  selectHandler(e) {
    this.$refs.dialog.hide();
  }

  closeHandler() {
    this.state.dialog?.resolveHandler(this.state.selectedIndex);
  }
}
</script>
