import _ from '@/apps/common/lodash';
import { parse, stringify } from 'qs';

const RouterApproachPush = 'push';
const RouterApproachReplace = 'replace';
const FilterStringParserConfig = { arrayLimit: 100 };

export function createModelFilterMixin({ routeName, stateProp, storageProp, fieldSchema, fieldPredicate }) {
  return {
    watch: {
      $_modelFilterMixin_stateFilterString(fs) {
        this.$_modelFilterMixin_sync(fs, RouterApproachPush);
      },
      $_modelFilterMixin_routeFilterString(fs) {
        this.$_modelFilterMixin_sync(fs, RouterApproachPush);
      }
    },
    beforeCreate() {
      const { filter } = this.$store.state[stateProp];
      filter.current = fromFilterString(getStorageFilterString(), filter.empty);
    },
    created() {
      const emptyFilterString = toFilterString(this.$_modelFilterMixin_empty);
      const fs =
        this.$_modelFilterMixin_routeFilterString !== emptyFilterString ? this.$_modelFilterMixin_routeFilterString : this.$_modelFilterMixin_stateFilterString;
      this.$_modelFilterMixin_sync(fs, RouterApproachReplace);
    },
    methods: {
      $_modelFilterMixin_sync(fs, approach) {
        if (this.$_modelFilterMixin_stateFilterString !== fs) {
          this.$_modelFilterMixin_syncStateWithFilterString(fs);
        } else if (this.$_modelFilterMixin_routeFilterString !== fs) {
          this.$_modelFilterMixin_syncRouteWithFilterString(fs, approach);
        } else {
          setStorageFilterString(fs);
          this.loadItems();
        }
      },
      $_modelFilterMixin_syncRouteWithFilterString(fs, approach) {
        this.$router[approach](`/${routeName}/filter/${fs}`);
      },
      $_modelFilterMixin_syncStateWithFilterString(fs) {
        this.$_modelFilterMixin_state.filter.current = fromFilterString(fs, this.$_modelFilterMixin_empty);
      }
    },
    computed: {
      $_modelFilterMixin_state() {
        return this.$store.state[stateProp];
      },
      $_modelFilterMixin_empty() {
        return this.$_modelFilterMixin_state.filter.empty;
      },
      $_modelFilterMixin_stateFilterString() {
        return toFilterString(this.$_modelFilterMixin_state.filter.current);
      },
      $_modelFilterMixin_routeFilterString() {
        return toFilterString(fromFilterString(this.$route.params.filter, this.$_modelFilterMixin_empty));
      }
    }
  };

  function toFilterString(filter) {
    const picked = _.pickBy(filter, fieldPredicate);
    return decodeURIComponent(stringify(picked, { sort: compareStringsAsc }));
  }

  function fromFilterString(fs, baseFilter) {
    const parsed = parse(fs, FilterStringParserConfig);
    const filter = _.transform(parsed, transformFilterField, {});
    return Object.assign(_.cloneDeep(baseFilter), filter);
  }

  function transformFilterField(filter, value, key) {
    if (fieldPredicate(value, key)) {
      filter[key] = fieldSchema[key] ? formatFilterFieldValue(value, fieldSchema[key]) : value;
    }
  }

  function getStorageFilterString() {
    return localStorage[storageProp] || '';
  }

  function setStorageFilterString(fs) {
    localStorage[storageProp] = fs;
  }
}

function formatFilterFieldValue(value, { multiple = false, identity = (v) => v }) {
  if (multiple) {
    return value ? (Array.isArray(value) ? value : [value]).map(identity) : [];
  }
  return identity(value);
}

function compareStringsAsc(stringA, stringB) {
  return stringA.localeCompare(stringB);
}
