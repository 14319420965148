<template>
  <div class="cases-filter">
    <el-form :size="$vars.sizes.form" :label-position="$vars.forms.label_position" :labelWidth="$vars.forms.label_width" :name="$options.name">
      <el-form-item :label="$tf('name')">
        <el-input name="name_contains" v-model="filter.name_contains" @input="clearOnChange('name_contains')"></el-input>
        <el-button @click="clearFilter('name_contains')" v-if="hasFilter('name_contains')" class="clear-filter--button" circle size="mini" icon="el-icon-close">
        </el-button>
      </el-form-item>

      <el-form-item :label="$tf('kusp_id')">
        <el-input name="claim_id" v-model="filter.claim_id" @input="clearOnChange('claim_id')"></el-input>
        <el-button @click="clearFilter('claim_id')" v-if="hasFilter('claim_id')" class="clear-filter--button" circle size="mini" icon="el-icon-close">
        </el-button>
      </el-form-item>

      <el-form-item :label="$tfo('active')">
        <el-select name="has-faces" v-model="filter.active" placeholder="">
          <el-option value="" :label="$tfo('all')"></el-option>
          <el-option value="True" :label="$tfo(['only_active'])"></el-option>
          <el-option value="False" :label="$tfo(['only_not_active'])"></el-option>
        </el-select>
      </el-form-item>

      <el-form-item :label="$tf('date | from')">
        <el-date-picker
          name="created-date-gte"
          v-model="filter.created_date_gte"
          type="datetime"
          align="right"
          ref="startDate"
          @input="(v) => changeDateFilter('created_date_gte', v)"
          placeholder=""
        ></el-date-picker>
        <el-button
          class="clear-filter--button"
          circle
          size="mini"
          icon="el-icon-close"
          @click="clearFilter('created_date_gte')"
          v-if="hasFilter('created_date_gte')"
        >
        </el-button>
      </el-form-item>

      <el-form-item :label="$tf('date | till')">
        <el-date-picker
          name="created-date-lte"
          v-model="filter.created_date_lte"
          type="datetime"
          align="right"
          ref="endDate"
          @input="(v) => changeDateFilter('created_date_lte', v)"
          placeholder=""
        ></el-date-picker>
        <el-button
          class="clear-filter--button"
          circle
          size="mini"
          icon="el-icon-close"
          @click="clearFilter('created_date_lte')"
          v-if="hasFilter('created_date_lte')"
        >
        </el-button>
      </el-form-item>

      <el-form-item :label="$tfo('date | kusp,,1 | from')">
        <el-date-picker
          name="claim-creation-date-gte"
          v-model="filter.claim_creation_date_gte"
          type="datetime"
          align="right"
          ref="startDate"
          @input="(v) => changeDateFilter('claim_creation_date_gte', v)"
          placeholder=""
        ></el-date-picker>
        <el-button
          class="clear-filter--button"
          circle
          size="mini"
          icon="el-icon-close"
          @click="clearFilter('claim_creation_date_gte')"
          v-if="hasFilter('claim_creation_date_gte')"
        >
        </el-button>
      </el-form-item>

      <el-form-item :label="$tfo('date | kusp,,1 | till')">
        <el-date-picker
          name="claim-creation-date-lte"
          v-model="filter.claim_creation_date_lte"
          type="datetime"
          align="right"
          ref="endDate"
          @input="(v) => changeDateFilter('claim_creation_date_lte', v)"
          placeholder=""
        ></el-date-picker>
        <el-button
          class="clear-filter--button"
          circle
          size="mini"
          icon="el-icon-close"
          @click="clearFilter('claim_creation_date_lte')"
          v-if="hasFilter('claim_creation_date_lte')"
        >
        </el-button>
      </el-form-item>

      <el-form-item :label="$tf('date | incident,,1 | from')">
        <el-date-picker
          name="incident-date-gte"
          v-model="filter.incident_date_gte"
          type="datetime"
          align="right"
          ref="startDate"
          @input="(v) => changeDateFilter('incident_date_gte', v)"
          placeholder=""
        ></el-date-picker>
        <el-button
          class="clear-filter--button"
          circle
          size="mini"
          icon="el-icon-close"
          @click="clearFilter('incident_date_gte')"
          v-if="hasFilter('incident_date_gte')"
        >
        </el-button>
      </el-form-item>

      <el-form-item :label="$tf('date | incident,,1 | till')">
        <el-date-picker
          name="incident-date-lte"
          v-model="filter.incident_date_lte"
          type="datetime"
          align="right"
          ref="endDate"
          @input="(v) => changeDateFilter('incident_date_lte', v)"
          placeholder=""
        ></el-date-picker>
        <el-button
          class="clear-filter--button"
          circle
          size="mini"
          icon="el-icon-close"
          @click="clearFilter('incident_date_lte')"
          v-if="hasFilter('incident_date_lte')"
        >
        </el-button>
      </el-form-item>

      <el-form-item :label="$tf('looks_like')" v-if="filter.looks_like">
        <el-input name="looks_like" v-model="filter.looks_like" @input="clearOnChange('looks_like')"></el-input>
        <el-button @click="clearFilter('looks_like')" v-if="hasFilter('looks_like')" class="clear-filter--button" circle size="mini" icon="el-icon-close">
        </el-button>
      </el-form-item>

      <el-form-item>
        <el-button name="clear-btn" :disabled="!hasFilters" @click="clearFilters">{{ $tf('reset | filters') }} </el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import _ from '@/apps/common/lodash';
import FilterMixin from '@/apps/common/mixins/filter';

export default {
  mixins: [FilterMixin],
  name: 'cases-filter',
  data: function () {
    return {
      model: 'cases',
      route: 'cases',
      hasFilters: true,
      disableStore: true
    };
  },
  computed: {
    state: function () {
      return this.$store.state.cases;
    },
    filter: function () {
      return this.state.filter.current;
    }
  },
  watch: {},
  created() {
    this.loadItems = _.throttle(this.loadItems, 750);
    this.clearDates = this.clearDates.bind(this);
  },
  mounted() {
    this.state.prev_page = this.filter.page ? [''] : [];
    this.loadItems();
  },
  methods: {
    getParamsSchema() {
      const parseInt10 = (v) => parseInt(v, 10),
        parseIntOrNull = (v) => (v ? parseInt(v, 10) : null);

      return {
        limit: { multiple: false, identity: parseInt10 },
        request: { multiple: false, identity: parseIntOrNull }
      };
    },
    hasFilter(v) {
      let r = false;
      switch (v) {
        default:
          r = !_.isEqual(this.filter[v], this.state.filter.empty[v]);
          break;
      }
      return r;
    },
    clearFilter(v) {
      switch (v) {
        default:
          this.filter[v] = _.cloneDeep(this.state.filter.empty[v]);
          break;
      }
      this.clearOnChange(v);
      this.clearDates();
    },
    changeDateFilter(name, v) {
      const dateValue = (v && v.toISOString && v.toISOString()) || null,
        currentDate = this.filter[name],
        currentValue = (currentDate && currentDate.toISOString && currentDate.toISOString()) || currentDate;
      if (currentValue === dateValue) return;
      this.clearOnChange(name);
      this.filter[name] = v;
    },
    clearOnChange(type) {
      switch (type) {
        default:
          break;
      }

      this.filter.page = '';
      this.state.prev_page = [];
      this.state.next_page = null;
    },
    loadItems() {
      this.$store.dispatch(this.state.Action.Get).catch((e) => {
        this.$notify({ duration: 0, message: this.$createElement('message-box', { props: { e } }) });
      });
    },
    clearFilters(e) {
      this.state.filter.current = _.cloneDeep(this.state.filter.empty);
    },
    clearDates() {}
  }
};
</script>

<style lang="stylus">

.search-events-filter {
  max-width: 240px;

  .el-autocomplete, .el-select {
    display: block;
  }
}
</style>
