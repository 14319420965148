<template>
  <div class="screenshot">
    <div class="screenshotter" v-if="webCameras.length">
      <el-form
        ref="form"
        v-if="webCameras.length > 1"
        :size="$vars.sizes.form"
        :label-position="$vars.forms.label_position"
        :labelWidth="$vars.forms.label_width"
        :name="$options.name"
        class="events-filter"
      >
        <el-form-item :label="$tf('common.camera')">
          <el-select name="cameras" v-model="camera" :placeholder="$tf('common.not_selected')">
            <el-option v-for="item in webCameras" :key="'cameras-' + item.deviceId" :label="getWebCameraLabel(item)" :value="item"> </el-option>
          </el-select>
          <el-button class="clear-filter--button" circle size="mini" icon="el-icon-close" @click="clearFilter('cameras')" v-if="camera"> </el-button>
        </el-form-item>
      </el-form>

      <template v-if="camera">
        <canvas v-show="evaluating" id="canvas" ref="canvas"></canvas>
        <video v-show="!evaluating" ref="video"></video>

        <div class="screenshotter-dialog-footer" slot="footer">
          <el-button v-if="!evaluating" name="screenshot-btn" type="primary" @click="makeScreenshot">{{ $tfo('make_screenshot') }}</el-button>
          <template v-else>
            <el-button name="save-btn" type="primary" @click="saveScreenshot">{{ $tf('save_screenshot') }}</el-button>
            <el-button name="redo-btn" type="secondary" @click="redoScreenshot">{{ $tf('redo_screenshot') }}</el-button>
          </template>
        </div>
      </template>
    </div>

    <div v-else class="error">
      <h1>{{ $tf('no_webcams_available') }}</h1>
    </div>
  </div>
</template>

<script>
import _ from '@/apps/common/lodash';

export default {
  name: 'screenshot',
  data() {
    return {
      camera: null,
      webCameras: [],
      snap: () => {},
      imageBlob: null,
      evaluating: false,
      stream: null,
      onLoadedMetadata: null
    };
  },
  watch: {
    camera(v) {
      if (!v) return;
      this.showVideo();
    }
  },
  computed: {
    webCamConstraints() {
      const { deviceId, groupId } = this.camera;
      const constraints = _.omitBy({ deviceId, groupId }, _.isNil);
      return _.isEmpty(constraints) ? true : constraints;
    }
  },
  methods: {
    redoScreenshot() {
      this.evaluating = false;
    },
    makeScreenshot() {
      this.snap();
    },
    saveScreenshot() {
      if (!this.imageBlob) return;
      this.$emit('screenshot', this.imageBlob);
    },
    clearFilter() {
      this.camera = null;
    },
    getWebCameras() {
      navigator.mediaDevices
        .enumerateDevices()
        .then((x) => x.filter((d) => d.kind === 'videoinput'))
        .then((w) => {
          this.webCameras = w;
          if (w.length === 1) this.camera = w[0];
        });
    },
    getWebCameraLabel(v) {
      return v.label || v.name || v.deviceId || v.groupId || JSON.stringify(v);
    },
    showVideo() {
      this.imageBlob = null;
      navigator.mediaDevices
        .getUserMedia({ audio: false, video: this.webCamConstraints })
        .then((stream) => {
          this.stream = stream;
          const video = this.$refs.video;

          if ('srcObject' in video) {
            video.srcObject = stream;
          } else {
            video.src = window.URL.createObjectURL(stream);
          }
          video.play();

          const canvas = this.$refs.canvas;
          const context = canvas.getContext('2d');

          video.addEventListener(
            'loadedmetadata',
            function onLoadedMetadata() {
              this.onLoadedMetadata = onLoadedMetadata;
              canvas.width = video.videoWidth;
              canvas.height = video.videoHeight;
            },
            false
          );

          this.snap = () => {
            context.fillRect(0, 0, canvas.width, canvas.height);
            context.drawImage(video, 0, 0, canvas.width, canvas.height);
            canvas.toBlob((blob) => {
              this.imageBlob = blob;
              this.evaluating = true;
              this.$notify({ type: 'success', title: this.$tf('screenshot'), message: this.$tfo('action | success') });
            });
          };
        })
        .catch((e) => {
          this.$notify.error({
            title: 'Webcam start error',
            message: 'Can not get video stream from webcam. Check web camera connection and permissions. Details: ' + e
          });
          console.warn(e);
        });
    },
    closeStream() {
      if (!this.stream) return;
      this.stream.getTracks().forEach((track) => {
        if (track.readyState === 'live') {
          track.stop();
        }
      });
    }
  },
  mounted() {
    this.getWebCameras();
  },
  beforeDestroy() {
    this.closeStream();
    this.$refs.video.removeEventListener('loadedmetadata', this.onLoadedMetadata);
  }
};
</script>

<style lang="stylus">
.screenshot {
  margin: 1rem;

  .screenshotter {
    text-align: center;

    &-dialog-footer {
      margin: 3rem 0 2rem;
    }
  }

  canvas {
    outline: 4px solid green;
  }

  video {
    min-width: 640px;
    min-height: 320px;
  }

  .error {
    color: red;
  }
}
</style>
