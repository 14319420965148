import axios from 'axios';
import { convertPOTextToJSObject } from '../po';

const LoadTimeout = 10000;

function loadLanguage(state, { name, url }) {
  const loadUrl = `${url}?r=${Math.random()}`;
  return axios({ url: loadUrl, timeout: LoadTimeout })
    .then((v) => {
      // if (v && v.data) window.app.$i18n.messages[name] = v.data
      // console.log(v, name)
      return { name: name, data: convertPOTextToJSObject(v.data) };
    })
    .catch((e) => {
      console.warn(`[language] load error ${url}: ${e}`);
      return null;
    });
}

export function loadLanguages(state, payload) {
  const { languages } = state.config;
  const fileDefinition = (languages && languages.items) || [];
  const loadPromises = fileDefinition.map(({ name, url }) => loadLanguage(state, { name, url }));

  state.languages.loading = true;
  return Promise.all(loadPromises)
    .then((results) => {
      return results.filter((i) => !!i);
    })
    .catch((e) => {
      console.warn('[languages] load error: ', e);
    })
    .finally(() => {
      state.languages.loading = false;
    });
}
