<template>
  <filter-form-clear-button :visible="!isFieldClear" @clear="clearField">
    <el-select v-model="model" :placeholder="placeholder" :clearable="false" @change="clearLinkedFields">
      <el-option v-for="age of maxAge" :key="age" :label="age" :value="age" />
    </el-select>
  </filter-form-clear-button>
</template>

<script>
import { Component } from 'vue-property-decorator';
import FilterFormClearableComponent from './filter-form-component-clearable';

@Component({
  props: { maxAge: { type: Number, required: true } }
})
export default class FilterFormComponentAge extends FilterFormClearableComponent {}
</script>
