<template>
  <filter-form-clear-button :visible="!isFieldClear" @clear="clearField">
    <el-input :value="model" @input="handleInputValue" />
  </filter-form-clear-button>
</template>

<script>
import { Component } from 'vue-property-decorator';
import FilterFormClearableComponent from './filter-form-component-clearable';

@Component({
  props: { number: { type: Boolean, default: false } }
})
export default class FilterFormComponentInput extends FilterFormClearableComponent {
  handleInputValue(value) {
    if (this.number) {
      this.setModelValueWhenNumberStringIsValid(value);
    } else {
      this.setModelValue(value);
    }
  }

  setModelValueWhenNumberStringIsValid(value) {
    Number(value) >= 0 && this.setModelValue(value);
  }

  setModelValue(value) {
    this.clearLinkedFields((this.model = value));
  }
}
</script>
