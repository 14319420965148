







import { Prop, Component, Vue } from 'vue-property-decorator';
import { TableComponentNames } from '@/components/tables/components/names';
import { IAreaTrigger } from '@/store/areas/triggers';

@Component({
  name: TableComponentNames.AreaTriggerDescription
})
export default class TableGeneratorAreaTriggerDescription extends Vue {
  @Prop({ required: true })
  item!: IAreaTrigger;
}
