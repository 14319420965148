import { FilterFormComponentName } from '@/components/filter-form/components';
import { FieldName } from '@/store/events/filters/fields';

const ClearOnChangeFields = [FieldName.Page];

export const schema = {
  module: 'area_triggers',
  action: 'area_triggers/get',
  fields: [
    {
      name: FieldName.MaxBodyCountGte,
      control: {
        i18nLabel: ['people_count', 'from'],
        component: [
          FilterFormComponentName.Input,
          {
            number: true,
            clearFieldsOnChange: ClearOnChangeFields
          }
        ]
      }
    },
    {
      name: FieldName.MaxBodyCountLte,
      control: {
        i18nLabel: 'till',
        component: [
          FilterFormComponentName.Input,
          {
            number: true,
            clearFieldsOnChange: ClearOnChangeFields
          }
        ]
      }
    },
    {
      name: 'duration_gte',
      control: {
        i18nLabel: ['area_duration', 'from'],
        component: [
          FilterFormComponentName.Input,
          {
            number: true,
            clearFieldsOnChange: ClearOnChangeFields
          }
        ]
      }
    },
    {
      name: 'duration_lte',
      control: {
        i18nLabel: 'till',
        component: [
          FilterFormComponentName.Input,
          {
            number: true,
            clearFieldsOnChange: ClearOnChangeFields
          }
        ]
      }
    },
    {
      name: FieldName.CreatedDateGte,
      control: {
        i18nLabel: ['area_records_created_date', 'from'],
        component: [
          FilterFormComponentName.Datetime,
          {
            type: 'datetime',
            clearFieldsOnChange: ClearOnChangeFields
          }
        ]
      }
    },
    {
      name: FieldName.CreatedDateLte,
      control: {
        i18nLabel: 'till',
        component: [
          FilterFormComponentName.Datetime,
          {
            type: 'datetime',
            clearFieldsOnChange: ClearOnChangeFields
          }
        ]
      }
    }
  ]
};
