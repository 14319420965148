import * as filters from '@/apps/common/filters';
import _ from '@/apps/common/lodash';
import getCrud from '../get.crud';

const emptyFilter = Object.seal({
    id: '',
    page: '',
    limit: 20,
    //created_date_gte: null,
    //created_date_lte: null,
    first_event_date_gte: null,
    first_event_date_lte: null,
    matched_dossier: null,
    has_matches: '',
    matched_lists: [],
    cameras: [],
    camera_groups: [],
    events_count_gte: null,
    gender: [],
    age_gte: null,
    age_lte: null,
    emotions: [],
    glasses: [],
    beard: [],
    liveness: [],
    race: [],
    medmask: [],
    ordering: '-id'
  }),
  emptyItem = Object.seal({
    id: null,
    open: false,
    event_id: null,
    last_event_date: null,
    events_count: 0,
    matched_event_id: null
  }),
  Name = 'persons',
  Action = require('../action.names').default.Persons;

export default {
  state: {
    playing: true,
    loading: false,
    appending: false,
    page: '',
    next_page: null,
    limits: [10, 20, 50, 100, 200, 500],
    prev_page: [],
    items: [],
    videoWallItems: [],
    item: {
      emptyItem
    },
    eventsByPersonsId: {},
    filter: {
      empty: emptyFilter,
      current: _.cloneDeep(emptyFilter),
      videoWall: _.cloneDeep(emptyFilter)
    },
    Action
  },
  actions: Object.assign({ satisfyPerson }, getCrud(Name))
};

function satisfyPerson({ state, rootState }, person) {
  let filter = state.filter.current,
    cameras = rootState.cameras.items,
    satisfy = true;

  if (satisfy && filter.id) {
    satisfy = filter.id === person.id;
  }

  if (satisfy && filter.matched_dossier) {
    satisfy = filter.matched_dossier === (person.matched_dossier || '').toString();
  }

  if (satisfy && filter.has_matches) {
    satisfy = filter.has_matches === 'True' ? !!person.matched_dossier : !person.matched_dossier;
  }

  if (satisfy && filter.matched_lists.length) {
    satisfy = _.intersection(filter.matched_lists, person.matched_lists).length > 0;
  }

  if (satisfy && filter.camera_groups.length) {
    let camera = cameras.find((v) => v.id === person.camera),
      group = filter.camera_groups.find((v) => v === camera.group);

    satisfy = !!group;
  }

  if (satisfy && filter.cameras.length) {
    satisfy = !!filter.cameras.find((v) => v === person.camera);
  }

  if (satisfy && filter.first_event_date_gte) {
    let isoStringToDate = filters.isoStringToDate,
      filterGTEDate = isoStringToDate(filter.first_event_date_gte),
      personDate = isoStringToDate(person.created_date);

    satisfy = personDate.getTime() >= filterGTEDate.getTime();
  }

  if (satisfy && filter.first_event_date_lte) {
    let isoStringToDate = filters.isoStringToDate,
      filterLteDate = isoStringToDate(filter.first_event_date_lte),
      personDate = isoStringToDate(person.created_date);

    satisfy = personDate.getTime() <= filterLteDate.getTime();
  }

  if (satisfy && filter.gender.length) {
    let r = _.get(person, 'features.gender.name'),
      item = filter.gender.find((v) => v === r);
    satisfy = !!item;
  }

  if (satisfy && filter.age_gte) {
    satisfy = person.features.age >= filter.age_gte;
  }

  if (satisfy && filter.age_lte) {
    satisfy = person.features.age <= filter.age_lte;
  }

  if (satisfy && filter.emotions.length) {
    let r = _.get(person, 'features.emotions.name'),
      item = filter.emotions.find((v) => v === r);
    satisfy = !!item;
  }

  if (satisfy && filter.glasses.length) {
    let r = _.get(person, 'features.glasses.name'),
      item = filter.glasses.find((v) => v === r);
    satisfy = !!item;
  }

  if (satisfy && filter.beard.length) {
    let r = _.get(person, 'features.beard.name'),
      item = filter.beard.find((v) => v === r);
    satisfy = !!item;
  }

  if (satisfy && filter.liveness.length) {
    let r = _.get(person, 'features.liveness.name'),
      item = filter.liveness.find((v) => v === r);
    satisfy = !!item;
  }

  if (satisfy && filter.race.length) {
    let r = _.get(person, 'features.race.name'),
      item = filter.race.find((v) => v === r);
    satisfy = !!item;
  }

  return satisfy;
}
