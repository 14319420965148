/*
Source: npm/po2json
*/
import _ from '@/apps/common/lodash';
import { parse } from './parser';

const MSGID = 'msgid';
const MSGSTR = 'msgstr';
const MSGCTXT = 'msgctxt';

const date = new Date();
const TODAY = `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;

const DEFAULT_VALUES = {
  'Project-Id-Version': 'findface-security',
  'POT-Creation-Date': TODAY,
  'PO-Revision-Date': TODAY,
  'Last-Translator': '',
  'Language-Team': 'ui',
  'MIME-Version': '1.0',
  'Content-Type': 'text/plain; charset=utf-8',
  'Content-Transfer-Encoding': '8bit',
  Language: 'no',
  'X-Generator': 'None'
};

const constructHeader = (props) => {
  let res = [];
  res.push(`${MSGID} ""`);
  res.push(`${MSGSTR} ""`);

  Object.keys(props).forEach((key) => {
    res.push(`"${key}: ${props[key]}\\n"`);
  });

  return res;
};

const isObject = (obj) => obj === Object(obj);

const populateTranslation = (res, trans, defaultTrans, prevKey) => {
  Object.keys(trans).forEach((key) => {
    const id = prevKey ? prevKey + '.' + key : key;
    if (isObject(trans[key])) {
      populateTranslation(res, trans[key], defaultTrans[key], id);
    } else {
      res.push('');
      res.push(`${MSGCTXT} "${id}"`);
      res.push(`${MSGID} ${JSON.stringify(defaultTrans[key] || '')}`);
      res.push(`${MSGSTR} ${JSON.stringify(trans[key] || '')}`);
    }
  });
};

const writeTranslations = (localeObject, defaultLocaleObject) => {
  let res = [];
  populateTranslation(res, localeObject, defaultLocaleObject);
  return res;
};

export function convertJSObjectToPOText(localeObject, defaultLocaleObject, properties = {}) {
  const props = Object.assign({}, DEFAULT_VALUES, properties);
  const header = constructHeader(props);
  const translations = writeTranslations(localeObject, defaultLocaleObject);
  const result = header.concat(translations).join('\n');
  return result;
}

export function convertPOTextToJSObject(content) {
  const parsed = parse(content),
    result = {};
  if (parsed.translations) {
    parsed.translations.forEach(({ msgctxt, msgid, msgstr }) => {
      const key = msgctxt,
        value = Array.isArray(msgstr) ? msgstr.join('\n') : msgstr;
      if (key) _.set(result, key, value || undefined);
    });
  }

  return result;
}
