<template>
  <confidence-select v-model="model" />
</template>

<script>
import { Component } from 'vue-property-decorator';
import ConfidenceSelect from '../../../common/confidence.select';
import BaseFormComponent from './component';

@Component({ components: { ConfidenceSelect } })
export default class BaseFormComponentConfidence extends BaseFormComponent {}
</script>
