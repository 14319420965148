<template>
  <div class="guide-page">
    <h1>Стили, которые используются в UI элементах приложений</h1>
    <h2>Цвета</h2>

    <el-table :data="getColorItems('colors')">
      <el-table-column prop="name" label="Name"></el-table-column>
      <el-table-column prop="value" width="100px" label="Hex"></el-table-column>
      <el-table-column width="100px" label="Color">
        <template slot-scope="{ row }">
          <div :style="{ backgroundColor: row.value }" class="abs-full"></div>
        </template>
      </el-table-column>
    </el-table>

    <h2>Цвета текста</h2>

    <el-table :data="getColorItems('text_colors')">
      <el-table-column prop="name" label="Name"></el-table-column>
      <el-table-column prop="value" width="100px" label="Hex"></el-table-column>
      <el-table-column width="100px" label="Color">
        <template slot-scope="{ row }">
          <div :style="{ backgroundColor: row.value }" class="abs-full"></div>
        </template>
      </el-table-column>
    </el-table>

    <h2>Цвета бэкграунда</h2>

    <el-table :data="getColorItems('background_colors')">
      <el-table-column prop="name" label="Name"></el-table-column>
      <el-table-column prop="value" width="100px" label="Hex"></el-table-column>
      <el-table-column width="100px" label="Color">
        <template slot-scope="{ row }">
          <div :style="{ backgroundColor: row.value }" class="abs-full"></div>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
export default {
  methods: {
    getColorItems(p) {
      return Object.keys(this.$vars[p])
        .sort()
        .map((v) => ({ name: this.$filters.firstCase(v), value: this.$vars[p][v] }));
    }
  }
};
</script>

<style>
.guide-page .el-table {
  max-width: 600px;
}

.color-block {
  display: inline-block;
  width: 5rem;
  height: 1rem;
}
</style>
