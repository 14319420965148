<template>
  <div :class="{ content: !included }" class="mar-v--2">
    <div>
      <h1>Content buttons / Контентные кнопки</h1>
      <h2>Buttons / Кнопки</h2>

      <el-button>Button</el-button>

      <el-button type="primary">Primary Button</el-button>
      <el-button type="text">Text Button</el-button>
      <el-button type="info">Info Button</el-button>

      <h2>Plain buttons / Кнопки упрощенные, без бэкграунда</h2>

      <el-button plain>Button</el-button>
      <el-button type="primary" plain>Primary Button</el-button>
      <el-button type="info" plain>Info Button</el-button>

      <h2>Disabled buttons / Отключенные</h2>

      <el-button disabled>Button</el-button>
      <el-button disabled type="primary">Primary Button</el-button>
      <el-button disabled type="text">Text Button</el-button>
      <el-button disabled type="text">Info Button</el-button>

      <h2>Buttons loading / Загрузка</h2>
      <el-button loading>Default Button</el-button>
      <el-button loading type="primary">Primary Button</el-button>
      <el-button loading type="text">Text Button</el-button>
      <el-button loading type="info">Info Button</el-button>

      <h2>Buttons sizes / Размеры</h2>
      <div :key="size" v-for="size in ['medium', 'small', 'mini']">
        <el-button type="primary" :size="size">Primary {{ size }} button</el-button>
        <el-button type="text" :size="size">Text {{ size }} button</el-button>
        <el-button type="info" :size="size">Info {{ size }} button</el-button>
      </div>
    </div>

    <div class="margin-top--big">
      <h1>Functional buttons / Функциональные кнопки</h1>
      <h2>Filled buttons</h2>

      <div :key="size" v-for="size in ['medium', 'small', 'mini']">
        <el-button type="success" :size="size">Success {{ size }}</el-button>
        <el-button type="warning" :size="size">Warning {{ size }}</el-button>
        <el-button type="danger" :size="size">Danger {{ size }}</el-button>
        <el-button type="info" :size="size">Info {{ size }}</el-button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'g-buttons',
  props: ['included'],
  data() {
    return {
      buttonTypes: ['primary', 'secondary', 'tetriary'],
      sizes: ['large', '', 'small', 'mini'],
      types: ['', 'primary', 'warning', 'text'],
      icons: [
        'arrow-down',
        'arrow-left',
        'arrow-right',
        'arrow-up',
        'caret-bottom',
        'caret-left',
        'caret-right',
        'caret-top',
        'check',
        'circle-check',
        'circle-close',
        'circle-cross',
        'close',
        'upload',
        'd-arrow-left',
        'd-arrow-right',
        'd-caret',
        'date',
        'delete',
        'document',
        'edit',
        'information',
        'loading',
        'menu',
        'message',
        'minus',
        'more',
        'picture',
        'plus',
        'search',
        'setting',
        'share',
        'star-off',
        'star-on',
        'time',
        'warning',
        'delete2',
        'upload2',
        'view'
      ],
      msg: 'Welcome to Your Vue.js App'
    };
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.margin-top--big {
  margin-top: 6rem;
}

ul {
  list-style-type: none;
  padding: 0;
}

li {
  display: inline-block;
  margin: 0 10px;
}

button {
  margin-bottom: 12px;
}
</style>
