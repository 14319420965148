<template>
  <el-button v-text="$tf('reset | filters')" @click="dispatchClickEvent" :disabled="disabled" />
</template>

<script>
import { Component, Vue } from 'vue-property-decorator';

@Component({
  props: { disabled: { type: Boolean, required: true } }
})
export default class FilterFormClearButton extends Vue {
  dispatchClickEvent() {
    this.$emit('click');
  }
}
</script>
