import { IAreaTrigger, IAreaTriggerFilter } from '@/store/areas/triggers';

export const EmptyItem: IAreaTrigger = {
  id: 0,
  created_date: '',
  area: 0,
  active: false,
  duration: 0,
  max_body_count: 0
};

export const EmptyFilter: IAreaTriggerFilter = {
  active: undefined,
  area: [],
  max_body_count_gte: undefined,
  max_body_count_lte: undefined,
  duration_gte: undefined,
  duration_lte: undefined,
  // created_date_gt: '',
  created_date_gte: '',
  // created_date_lt: '',
  created_date_lte: '',
  limit: 10,
  ordering: '',
  page: undefined
};

export const mockItems = [
  { ...EmptyItem, id: 1, area: 1, duration: 57, max_body_count: 50, created_date: new Date().toISOString() },
  { ...EmptyItem, id: 2, area: 1, duration: 2317, max_body_count: 3, created_date: new Date().toISOString() },
  { ...EmptyItem, id: 3, area: 1, duration: 17, max_body_count: 5, created_date: new Date().toISOString() },
  { ...EmptyItem, id: 4, area: 1, duration: 127, max_body_count: 128, created_date: new Date().toISOString() }
];
