import _ from 'lodash';

export default class MockService<T extends Record<string, any>> {
  items: T[] = [];

  constructor(items: T[]) {
    this.items = items;
    this.requestHandler = this.requestHandler.bind(this);
  }

  requestHandler({ method, data, id, filter }): Promise<any> {
    let result = new Promise((r, j) => r(null));

    switch (method) {
      case 'GET':
        const items = id || filter ? this.filterMockData(id, filter) : this.items;
        const axiosResponse = Array.isArray(items) ? this.getAxiosResponse(items) : items;
        result = new Promise((r, j) => r(axiosResponse));
        break;

      case 'POST':
        result = new Promise((r, j) => {
          const item = { ...data, id: Math.round(Math.random() * 1e6), created_data: new Date().toISOString() };
          this.items.push(item);
          r(item);
        });
        break;

      case 'PATCH':
        result = new Promise((r, j) => r(data));
        break;

      case 'DELETE':
        result = new Promise((r, j) => {
          const itemIndex = this.items.findIndex((v) => v.id === id);
          if (itemIndex > -1) this.items.splice(itemIndex, 1);
          r(true);
        });
        break;
    }

    return result;
  }

  filterMockData(id, filter) {
    let r: any = this.items;

    if (id) {
      r = this.items.find((v) => v.id === id);
    } else {
      if (filter?.cameras?.length) {
        r = r.filter((v) => {
          const intersection = _.intersection(v.cameras, filter.cameras);
          return !!intersection?.length;
        });
      }

      if (filter?.mobile !== undefined) {
        r = r.filter((v) => v.mobile === filter.mobile);
      }

      if (filter?.status) {
        r = r.filter((v) => v.status === filter.status);
      }

      if (filter?.uuid_contains) {
        r = r.filter((v) => v.uuid?.indexOf(filter.uuid_contains) > -1);
      }
    }

    return r;
  }

  getAxiosResponse(items) {
    return {
      results: items,
      next_page: 'abc?rnd=' + Math.random()
    };
  }
}
