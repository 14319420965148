<template>
  <el-select
    style="width: 100%"
    @change="clearLinkedFields"
    :multiple-limit="multipleLimit"
    :placeholder="placeholder"
    :no-data-text="$tf('no_camera')"
    v-model="model"
    filterable
    multiple
  >
    <el-option v-for="item in cameras" :key="item.id" :label="item.shortName" :value="item.id">
      <el-tooltip :content="item.name" placement="top">
        <span v-text="item.shortName"></span>
      </el-tooltip>
    </el-option>
  </el-select>
</template>

<script>
import _ from '@/apps/common/lodash';
import { Component } from 'vue-property-decorator';
import BaseFormComponent from './component';

@Component({
  props: {
    itemsPath: { type: String, required: true },
    multipleLimit: { type: Number, default: 10 },
    cameraGroupsFieldName: { type: String, default: null }
  }
})
export default class BaseFormComponentCameras extends BaseFormComponent {
  get items() {
    return _.get(this, this.itemsPath).map(this.normalizeCamera);
  }

  get cameras() {
    return this.areCameraGroupsSelected() ? this.selectGroupsCameras() : this.items;
  }

  get cameraGroups() {
    return this.form.getCurrentFieldValue(this.cameraGroupsFieldName);
  }

  areCameraGroupsSelected() {
    return this.cameraGroups?.length > 0;
  }

  selectGroupsCameras() {
    return this.items.filter(({ group }) => this.cameraGroups.includes(group));
  }

  normalizeCamera(item) {
    return { ...item, shortName: this.$filters.shortString(item.name) };
  }
}
</script>
