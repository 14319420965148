<template>
  <div class="common-list">
    <div v-for="item in items" class="common-list-item" :class="isSelected(item) && 'selected'" :key="item.name">
      <a @click.prevent="clickHandler(item)">
        {{ converter ? converter(item.name) : item.name }}
      </a>
    </div>
  </div>
</template>

<script>
export default {
  name: 'common-list',
  props: ['items', 'value', 'converter'],
  methods: {
    isSelected(item) {
      return this.value === item;
    },
    clickHandler(item) {
      const value = item;
      this.$emit('input', value);
      this.$emit('change', value);
    }
  }
};
</script>
