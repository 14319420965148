import getCrud from '../get.crud';

const Name = 'onvif-cameras';

function serializeOnvifCamera(onvif_camera) {
  return {
    id: onvif_camera.id,
    ...onvif_camera.meta.device_info,
    port: onvif_camera.meta.port,
    need_auth: onvif_camera.meta.need_auth,
    host: onvif_camera.meta.host,
    streams: onvif_camera.meta.streams,
    endpoint_reference: onvif_camera.endpoint_reference
  };
}

function itemsHandler({ results }) {
  return results.map(serializeOnvifCamera);
}

function getOnvifCameras({ rootState, state, dispatch }) {
  state.loading = true;
  return dispatch(rootState.Action.RequestApi, {
    model: Name,
    method: 'GET'
  })
    .then((response) => {
      state.items = itemsHandler(response);
      state.loading = false;
    })
    .catch((e) => {
      state.loading = false;
      return Promise.reject(e);
    });
}

function authOnvifCameras({ rootState, state, getters, dispatch }, { id, login, password }) {
  return dispatch(rootState.Action.RequestApi, {
    model: Name + `/${id}/auth`,
    method: 'POST',
    data: {
      login,
      password
    }
  }).then(serializeOnvifCamera);
}

export default {
  state: {
    loading: false,
    items: []
  },
  actions: Object.assign({ getOnvifCameras, authOnvifCameras }, getCrud(Name))
};
