// import qs from 'qs'
import _ from '@/apps/common/lodash';
import getCrud from '../get.crud';

const emptyFilter = {
    limit: 10,
    page: ''
  },
  emptyItem = {
    name: '',
    created_date: ''
  },
  Name = 'batch-upload',
  Action = require('../action.names').default.BatchUpload;

export default {
  state: {
    loading: false,
    page: '',
    next_page: null,
    prev_page: [],
    items: [],
    item: {
      current: _.cloneDeep(emptyItem),
      empty: emptyItem
    },
    filter: {
      empty: emptyFilter,
      current: _.cloneDeep(emptyFilter)
    },
    Action
  },
  actions: Object.assign(getCrud(Name))
};
