<template>
  <el-dialog custom-class="reports-dialog-layout" :title="$tf(['create', 'report'])" :visible.sync="visible" modal>
    <slot />
    <slot slot="footer" name="footer" />
  </el-dialog>
</template>

<script>
import { Component, Vue } from 'vue-property-decorator';

@Component({
  name: 'ReportsDialogLayout',
  props: {
    value: { type: Boolean, required: true }
  }
})
export default class extends Vue {
  get visible() {
    return this.value;
  }

  set visible(value) {
    this.$emit('input', value);
  }
}
</script>

<style>
.reports-dialog-layout {
  top: 50%;
  overflow: auto;
  max-height: 90%;
  width: 24rem !important;
  margin: 0 auto !important;
  transform: translateY(-50%);
}
</style>
