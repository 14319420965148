import { components, operations } from '@/definitions/openapi';
import { BaseItemsStateModule } from '@/definitions/base.items.state.module';
import { Module, RegisterOptions } from 'vuex-class-modules';
import { EmptyFilter, EmptyItem, mockAuthSessionService } from '@/store/sessions/sessions/items';
import store from '../../index';
import _ from '@/apps/common/lodash';
import { schema } from '@/store/sessions/sessions/filter.schema';

export type IAuthSession = components['schemas']['AuthSession'];
export type IAuthSessionFilter = operations['sessions_list']['parameters']['query'];

@Module({ generateMutationSetters: true })
export class AuthSessionsModule extends BaseItemsStateModule<IAuthSession, IAuthSessionFilter> {
  name = 'sessions';
  routeName = 'authSessions';

  constructor(options: RegisterOptions) {
    super(options);

    this.filter.empty = _.cloneDeep(EmptyFilter);
    this.filter.current = _.cloneDeep(EmptyFilter);
    this.filter.schema = schema;
    this.item.empty = _.cloneDeep(EmptyItem);

    // this.setCustomRequestHandler(mockAuthSessionService.requestHandler);
  }

  async dispatchImplementation(action: string, payload: any): Promise<any> {
    return store.dispatch(action, payload);
  }
}
