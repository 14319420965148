import { isObject } from '@/components/users/common';
import axios from 'axios';
import store from '@/store';

export function createVideoAuthenticationService({ app, config }) {
  function login(video) {
    return requestApi({ url: 'auth/video-auth/', video });
  }

  function renew(video) {
    return requestApi({
      url: 'auth/video-auth-renew/',
      headers: { authorization: 'Token ' + encodeURIComponent(app.token) },
      video
    });
  }

  function requestApi(options) {
    const headers = {
      ...(options.headers || {}),
      ...createRequiredRequestHeaders()
    };
    const body = createRequestBody(options.video);
    const url = createRequestAbsoluteUrl(options.url);
    return axios
      .post(url, body, { headers })
      .then(({ data }) => data)
      .catch(throwResponseBodyOrError);
  }

  function createRequiredRequestHeaders() {
    return { accept: 'application/json' };
  }

  function createRequestAbsoluteUrl(url) {
    return config.server.url + url;
  }

  return { login, renew };
}

function createRequestBody(video) {
  const body = new FormData();
  body.append('video', video);
  body.append('type', video.type);
  body.append('uuid', store.state.app.uuid);
  body.append('mobile', 'false');
  body.append('device_info', JSON.stringify({ user_agent: window.navigator.userAgent }));
  return body;
}

function throwResponseBodyOrError(error) {
  throw isObject(error.response) && isObject(error.response.data) ? error.response.data : error;
}
