import _ from '@/apps/common/lodash';
import store from '@/store';

export default function hasNoPermission(v, rule = 'or') {
  let items = v instanceof Array ? v : [v],
    resultLength = (_.intersection(items, store.state.users.current.permissions) || []).length,
    result = rule === 'and' ? resultLength === items.length : resultLength > 0,
    hasAcl = store.getters.hasAcl;

  return hasAcl ? !result : false;
}
