<template>
  <filter-form-clear-button :visible="!isFieldClear" @clear="clearField">
    <el-select v-model="model" :multiple="multiple" :filterable="filterable" :placeholder="placeholder" :allow-create="allowCreate" @change="clearLinkedFields">
      <el-option v-for="item of i18nItems" :label="item.computedLabel" :value="item.value" :key="item.value" />
    </el-select>
  </filter-form-clear-button>
</template>

<script>
import { Component } from 'vue-property-decorator';
import FilterFormClearableComponent from './filter-form-component-clearable';

@Component({
  props: {
    items: { type: Array, required: true },
    multiple: { type: Boolean, default: false },
    filterable: { type: Boolean, default: false },
    colorIcons: { type: Boolean, default: false },
    allowCreate: { type: Boolean, default: false },
    makeField: { type: String, default: '' },
    alphabeticalOrder: { type: Boolean, default: false }
  }
})
export default class FilterFormComponentCarsModelSelect extends FilterFormClearableComponent {
  get i18nItems() {
    const items = this.items
      .filter((v) => {
        return this.makeItems?.length ? this.makeItems.find((make) => v.label.indexOf(`${make}/`) === 0) : true;
      })
      .map((item) => ({ ...item, computedLabel: this.computeItemLabel(item) }));

    return this.alphabeticalOrder && items.sort(compareItemComputedLabelsAsc), items;
  }

  get makeItems() {
    return this.form.getCurrentFieldValue(this.makeField);
  }

  computeItemLabel({ i18nLabel, label }) {
    return (i18nLabel && this.$tf(i18nLabel)) ?? label;
  }
}

function compareItemComputedLabelsAsc(itemA, itemB) {
  return itemA.computedLabel.localeCompare(itemB.computedLabel);
}
</script>
