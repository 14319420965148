import { components, operations } from '@/definitions/openapi';
import { BaseItemsStateModule } from '@/definitions/base.items.state.module';
import { Module, RegisterOptions } from 'vuex-class-modules';
import { EmptyFilter, EmptyItem } from '@/store/areas/triggers/items';
import store from '../../index';
import _ from '@/apps/common/lodash';
import { schema } from '@/store/areas/triggers/filter.schema';

export type IAreaTrigger = components['schemas']['AreaTriggerActivation'];
export type IAreaTriggerFilter = operations['area_trigger_activations_list']['parameters']['query'];

@Module({ generateMutationSetters: true })
export class AreaTriggersModule extends BaseItemsStateModule<IAreaTrigger, IAreaTriggerFilter> {
  name = 'area-trigger-activations';
  routeName = 'areaTriggers';

  constructor(options: RegisterOptions) {
    super(options);

    this.filter.empty = _.cloneDeep(EmptyFilter);
    this.filter.current = _.cloneDeep(EmptyFilter);
    this.filter.schema = schema;
    this.item.empty = _.cloneDeep(EmptyItem);
  }

  async dispatchImplementation(action: string, payload: any): Promise<any> {
    return store.dispatch(action, payload);
  }
}
