import { isFunction, isObject } from './common';

const SupportedVideoTypes = ['video/mp4', 'video/webm;codecs=vp8', 'video/webm;codecs=vp9', 'video/webm;codecs=h264'];
const VideoInputConstraints = Object.freeze({
  width: { max: 1920 },
  height: { max: 1080 },
  frameRate: { min: 10, max: 29 }
});

export async function createVideoStream() {
  return navigator.mediaDevices.getUserMedia({ video: VideoInputConstraints });
}

export function disposeVideoStream(stream) {
  stream.getVideoTracks().forEach((track) => track.stop());
}

export function recordVideo(stream, length) {
  const recorder = new MediaRecorder(stream, {
    mimeType: computeSupportedVideoType()
  });
  setTimeout(() => recorder.stop(), length);
  return new Promise((resolve, reject) => {
    recorder.ondataavailable = ({ data }) => resolve(data);
    recorder.onerror = reject;
    recorder.start();
  });
}

export function doesUserAgentSupportVideoRecording() {
  return isMediaDevicesApiAvailable() && isMediaRecorderApiAvailable() && !!computeSupportedVideoType();
}

function isMediaDevicesApiAvailable() {
  return isObject(navigator.mediaDevices) && isFunction(navigator.mediaDevices.getUserMedia);
}

function isMediaRecorderApiAvailable() {
  return typeof MediaRecorder === 'function';
}

function computeSupportedVideoType() {
  return SupportedVideoTypes.find((codec) => MediaRecorder.isTypeSupported(codec));
}
