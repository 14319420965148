// import qs from 'qs'
import axios from 'axios';

export default {
  state: {
    loading: false,
    items: []
  },
  actions: { loadLanguage, loadLanguages }
};

function loadLanguage({ state, rootState, dispatch }, { name, url }) {
  const loadUrl = `${url}?r=${Math.random()}`;
  return axios({ url: loadUrl, timeout: 10000 }).then((v) => {
    if (v && v.data) window.app.$i18n.messages[name] = v.data;
    return v;
  });
}

export function loadLanguages({ state, rootState, dispatch }, payload) {
  const { languages } = rootState.config;
  const fileDefinition = (languages && languages.items) || [];
  const loadPromises = fileDefinition.map(({ name, url }) => dispatch('loadLanguage', { name, url }));

  state.loading = true;
  Promise.all(loadPromises)
    .then((v) => {})
    .catch((e) => {
      console.error('[languages] load error: ', e);
    })
    .finally(() => {
      state.loading = false;
    });
}
