import { FilterFormComponentName } from '@/components/filter-form/components';
import { FieldName } from '@/store/events/filters/fields';

const DefaultClearableFields = [FieldName.Page, FieldName.Id, FieldName.CreatedDateGte, FieldName.CreatedDateLte];

export const schema = {
  module: 'areas',
  action: 'areas/get',
  fields: [
    {
      name: FieldName.CameraGroups,
      control: {
        i18nLabel: 'camera_groups',
        component: [
          FilterFormComponentName.CameraGroups,
          {
            itemsPath: '$store.state.camera_groups.items',
            i18nPlaceholder: 'not_selected',
            clearFieldsOnChange: DefaultClearableFields
          }
        ]
      },
      meta: { multiple: true, identity: 'int' }
    },
    {
      name: FieldName.Cameras,
      control: {
        i18nLabel: 'cameras',
        component: [
          FilterFormComponentName.Cameras,
          {
            itemsPath: '$store.state.cameras.items',
            i18nPlaceholder: 'not_selected',
            cameraGroupsFieldName: 'camera_groups',
            clearFieldsOnChange: DefaultClearableFields
          }
        ]
      },
      meta: { multiple: true, identity: 'int' }
    }
  ]
};
