



import { Component, Vue, Watch } from 'vue-property-decorator';

interface INotification {
  type: 'success' | 'error';
  message?: string;
  duration?: number;
}

@Component({
  name: 'app-notifications'
})
export default class AppNotifications extends Vue {
  mounted() {}

  @Watch('$store.state.notifications.last')
  lastChanged(v: INotification | null, p: INotification | null) {
    if (v && v.message !== p?.message) {
      if (v.type === 'error') {
        this.$notify({ duration: 0, message: this.$createElement('message-box', { props: { e: v.message } }) });
      } else {
        this.$notify({ type: v.type, message: v.message, duration: v.duration || 5000 });
      }
    }
  }
}
