























import { Component } from 'vue-property-decorator';
import BaseFormComponent from '../component.vue';

const TriggerSelectOptions = {
  Monday: 'Mon',
  Tuesday: 'Tue',
  Wednesday: 'Wed',
  Thursday: 'Thu',
  Friday: 'Fri',
  Saturday: 'Sat',
  Sunday: 'Sun'
};

const TriggerSelectItems = [
  TriggerSelectOptions.Monday,
  TriggerSelectOptions.Tuesday,
  TriggerSelectOptions.Wednesday,
  TriggerSelectOptions.Thursday,
  TriggerSelectOptions.Friday,
  TriggerSelectOptions.Saturday,
  TriggerSelectOptions.Sunday
];

@Component
export default class BaseFormComponentAreaCalendar extends BaseFormComponent {
  get requiredRule() {
    return this.getTranslatedRule({
      required: true,
      props: {
        i18nMessage: 'error.required.field'
      }
    });
  }

  get requiredArray() {
    return this.getTranslatedRule({
      required: true,
      name: 'array',
      props: {
        i18nMessage: 'error.array.empty',
        minLength: 1
      }
    });
  }

  get selectItems() {
    return TriggerSelectItems.map((v) => ({ label: this.$tf(`date.${v.toLowerCase()}`), value: v }));
  }

  get trigger_calendar(): any[] {
    return this.form.getCurrentFieldValue('trigger_calendar');
  }

  set trigger_calendar(v: any[]) {
    this.form.setCurrentFieldValue('trigger_calendar', v);
  }

  get start_at(): string {
    return this.form.getCurrentFieldValue('start_at');
  }

  set start_at(v: string) {
    this.form.setCurrentFieldValue('start_at', v);
  }

  get end_at(): string {
    return this.form.getCurrentFieldValue('end_at');
  }

  set end_at(v: string) {
    this.form.setCurrentFieldValue('end_at', v);
  }

  addHandler() {
    this.trigger_calendar.push({ weekdays: [], start_at: '', end_at: '' });
  }

  removeHandler() {
    this.trigger_calendar.length && this.trigger_calendar.splice(this.trigger_calendar.length - 1, 1);
  }
}
