




import { Component, Vue } from 'vue-property-decorator';
import FilterForm from '@/components/filter-form/filter-form.vue';
import { AreasModule } from '@/store/areas/areas';

@Component({
  name: 'areas-filter',
  components: { FilterForm }
})
export default class AreasFilter extends Vue {
  get state(): AreasModule {
    return this.$store.state.areas as AreasModule;
  }
}
