// import qs from 'qs'
import _ from '@/apps/common/lodash';
import getCrud from '../get.crud';

const emptyFilter = {
    upload_list: '',
    page: '',
    resp_code_gt: 399,
    limit: 100
  },
  emptyItem = {
    id: '',
    resp_code: '',
    error_desc: '',
    dossier_id: null,
    upload_list: null,
    created_date: null
  },
  Name = 'batch-upload-entry',
  Action = require('../action.names').default.BatchUploadEntry;

export default {
  state: {
    loading: false,
    page: '',
    next_page: null,
    prev_page: [],
    items: [],
    item: {
      current: _.cloneDeep(emptyItem),
      empty: emptyItem
    },
    filter: {
      empty: emptyFilter,
      current: _.cloneDeep(emptyFilter)
    },
    Action
  },
  actions: Object.assign(getCrud(Name))
};
