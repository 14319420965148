<template>
  <div class="case-faces-filter">
    <el-form :size="$vars.sizes.form" :label-position="$vars.forms.label_position" :labelWidth="$vars.forms.label_width" :name="$options.name">
      <el-form-item :label="$tf('id | from')" v-if="filter.id_gte">
        <el-input name="id_gte" v-model="filter.id_gte" @input="clearOnChange('id_gte')"></el-input>
        <el-button @click="clearFilter('id_gte')" v-if="hasFilter('id_gte')" class="clear-filter--button" circle size="mini" icon="el-icon-close"> </el-button>
      </el-form-item>

      <el-form-item :label="$tf('video_archive')">
        <el-select name="group" v-model="filter.video_archive_in" :placeholder="$tf('common.select')">
          <el-option v-for="listItem in videos" :key="listItem.id" :value="String(listItem.id)" :label="$filters.shortString(listItem.name)" />
        </el-select>
        <!--<el-input name="video_archive" v-model="filter.video_archive_in" @input="clearOnChange('video_archive_in')"></el-input>-->
        <el-button
          @click="clearFilter('video_archive_in')"
          v-if="hasFilter('video_archive_in')"
          class="clear-filter--button"
          circle
          size="mini"
          icon="el-icon-close"
        >
        </el-button>
      </el-form-item>

      <el-form-item :label="$tf('looks_like')" v-if="filter.looks_like">
        <el-input name="looks_like" v-model="filter.looks_like" @input="clearOnChange('looks_like')"></el-input>
        <el-button @click="clearFilter('looks_like')" v-if="hasFilter('looks_like')" class="clear-filter--button" circle size="mini" icon="el-icon-close">
        </el-button>
      </el-form-item>

      <el-form-item>
        <el-button name="clear-btn" :disabled="!hasFilters" @click="clearFilters">{{ $tf('reset | filters') }} </el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import _ from '@/apps/common/lodash';
import FilterMixin from '@/apps/common/mixins/filter';

export default {
  mixins: [FilterMixin],
  name: 'case-faces-filter',
  data: function () {
    const id = this.$route.params.id;

    return {
      model: 'case_faces',
      route: `cases/${id}/faces`,
      disableStore: true,
      hasFilters: true,
      videoFilter: { limit: 1000, save_to: `cases:${id}`, ordering: 'id' },
      videos: []
    };
  },
  computed: {
    state: function () {
      return this.$store.state.case_faces;
    },
    filter: function () {
      return this.state.filter.current;
    }
  },
  watch: {},
  created() {
    this.loadItems = _.throttle(this.loadItems, 750);
    this.clearDates = this.clearDates.bind(this);
  },
  mounted() {
    this.state.prev_page = this.filter.page ? [''] : [];
    this.loadVideos();
    this.loadItems();
  },
  methods: {
    async loadVideos() {
      const response = await this.$store.dispatch(this.$store.state.videos.Action.Get, { filter: this.videoFilter });
      this.videos = response.results;
    },
    getParamsSchema() {
      const parseInt10 = (v) => parseInt(v, 10),
        parseIntOrNull = (v) => (v ? parseInt(v, 10) : null);

      return {
        limit: { multiple: false, identity: parseInt10 },
        request: { multiple: false, identity: parseIntOrNull }
      };
    },
    hasFilter(v) {
      let r = false;
      switch (v) {
        default:
          r = !_.isEqual(this.filter[v], this.state.filter.empty[v]);
          break;
      }
      return r;
    },
    clearFilter(v) {
      switch (v) {
        default:
          this.filter[v] = _.cloneDeep(this.state.filter.empty[v]);
          break;
      }
      this.clearOnChange(v);
      this.clearDates();
    },
    changeDateFilter(name, v) {
      const dateValue = (v && v.toISOString && v.toISOString()) || null,
        currentDate = this.filter[name],
        currentValue = (currentDate && currentDate.toISOString && currentDate.toISOString()) || currentDate;
      if (currentValue === dateValue) return;
      this.clearOnChange(name);
      this.filter[name] = v;
    },
    clearOnChange(type) {
      switch (type) {
        default:
          break;
      }

      this.filter.page = '';
      this.state.prev_page = [];
      this.state.next_page = null;
    },
    loadItems() {
      this.$store.dispatch(this.state.Action.Get).catch((e) => {
        this.$notify({ duration: 0, message: this.$createElement('message-box', { props: { e } }) });
      });
    },
    clearFilters() {
      this.state.filter.current = _.cloneDeep(this.state.filter.empty);
    },
    clearDates() {}
  }
};
</script>

<style lang="stylus">
.search-events-filter {
  max-width: 240px;

  .el-autocomplete, .el-select {
    display: block;
  }
}
</style>
