<template>
  <div class="content">
    <h4>Tables guide</h4>

    <div class="table-pagination">
      <span class="pull-left text-small">
        <el-button size="small" icon="el-icon-delete2" :disabled="!selectedItems.length"
          >Delete selected {{ selectedItems.length ? selectedItems.length + ' items ' : '' }}</el-button
        >
      </span>

      <span class="text-small">Page 1 of 125</span>
      <el-button-group size="small">
        <el-button plain disabled size="small" icon="el-icon-d-arrow-left"></el-button>
        <el-button plain disabled size="small" icon="el-icon-arrow-left"></el-button>
        <el-button plain size="small" icon="el-icon-arrow-right"></el-button>
        <el-button plain size="small" icon="el-icon-d-arrow-right"></el-button>
      </el-button-group>
    </div>

    <el-table stripe selectable @selection-change="setSelection" :data="items">
      <el-table-column type="selection" align="left" width="44"> </el-table-column>

      <el-table-column prop="date" align="left" label="First" width="180"> </el-table-column>

      <el-table-column prop="name" align="left" label="Second, tags" width="180">
        <template slot-scope="{ row }">
          <el-popover trigger="hover" placement="right" transition="none">
            <p>Name: {{ row.name }}</p>
            <p>Addr: {{ row.address }}</p>
            <div slot="reference" class="name-wrapper">
              <el-tag :key="name" type="primary" v-for="name in row.name">{{ name }}</el-tag>
            </div>
          </el-popover>
        </template>
      </el-table-column>

      <el-table-column prop="address" align="left" label="Third"> </el-table-column>
    </el-table>

    <div class="table-pagination">
      <span class="text-small">Show</span>
      <el-select size="small" v-model="rows" placeholder="count" class="table-select-rows">
        <el-option v-for="item in [5, 10, 20, 50, 100, 500]" :key="item" :label="item" :value="item"></el-option>
      </el-select>
      <span class="text-small">rows on page.</span>

      <span class="text-small">Page 1 of 125</span>
      <el-button-group size="small">
        <el-button plain disabled size="small" icon="el-icon-d-arrow-left"></el-button>
        <el-button plain disabled size="small" icon="el-icon-arrow-left"></el-button>
        <el-button plain size="small" icon="el-icon-arrow-right"></el-button>
        <el-button plain size="small" icon="el-icon-d-arrow-right"></el-button>
      </el-button-group>
    </div>
  </div>
</template>

<script>
import _ from '@/apps/common/lodash';

export default {
  name: 'tables',
  interval: 0,
  realTime: false,

  data() {
    return {
      selectedItems: [],
      rows: 10,
      items: this.getItems()
    };
  },
  mounted() {
    this.realTime &&
      (this.interval = setInterval(() => {
        this.items = this.getItems();
      }, 100));
  },

  methods: {
    setSelection(v) {
      this.selectedItems = v;
    },
    getItems() {
      return _.map(_.range(10), () => this.getItem());
    },
    getItem() {
      return {
        date: '2016-05-' + _.random(10, 31),
        name: _.random(1, 10) > 5 ? ['One ' + _.random(10, 1e3), 'One ' + _.random(1, 1e3)] : ['One ' + _.random(10, 1e3)],
        address: 'Two' + _.random(10, 1e3)
      };
    }
  }
};
</script>
<style scoped>
.table-select-rows {
  width: 5rem;
}

.text-small {
  font-size: 13px;
}

.table-pagination {
  width: 100%;
  text-align: right;
  margin: 10px 0;
}

.el-tag:first-child {
  margin-top: 12px;
}
.el-tag {
  margin-bottom: 12px;
  margin-right: 12px;
}
</style>
