




import Vue from 'vue';
import Component from 'vue-class-component';
import FilterForm from '@/components/filter-form/filter-form.vue';
import { AreaRecordsModule } from '@/store/areas/records';

@Component({
  name: 'area-records-filter',
  components: { FilterForm }
})
export default class AreaRecordsFilter extends Vue {
  get state(): AreaRecordsModule {
    return this.$store.state.area_records as AreaRecordsModule;
  }
}
