// import qs from 'qs'
import getCrud from '../../get.crud';
const Name = 'genetec/camera';

function import_genetec_camera({ state, rootState, dispatch }, payload) {
  return dispatch(rootState.Action.RequestApi, { model: Name + '/import', method: 'POST', data: payload });
}

export default {
  state: {
    filter: {
      empty: {},
      current: {}
    },
    loading: false,
    items: []
  },
  actions: Object.assign({ import_genetec_camera }, getCrud(Name))
};
