const ExtendVueWithForm = {};

ExtendVueWithForm.install = function install(Vue, options) {
  const vars = Vue.prototype.$vars;

  if (ExtendVueWithForm.installed) return;

  Vue.prototype.$form = {
    props: getFormProps(vars),
    getFormProps: (vNodeOptions, rules, model) => getFormProps(vars, vNodeOptions, rules, model)
  };

  ExtendVueWithForm.installed = true;
};

function getFormProps(vars, options, rules, model) {
  const result = {
    size: vars.sizes.form,
    labelPosition: vars.forms.label_position,
    name: (options || {}).name,
    rules: rules,
    model: model
  };

  return result;
}

export default ExtendVueWithForm;
