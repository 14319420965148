





import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

@Component({
  name: 'common-status'
})
export default class CommonStatus extends Vue {
  @Prop({ type: String })
  color?: string;

  @Prop({ type: String })
  colorDesc?: string;
}
