<template>
  <el-button type="primary" v-text="$tf('create')" @click="dispatchClickEvent" />
</template>

<script>
export default {
  name: 'ReportsDialogFormSubmitter',
  methods: {
    dispatchClickEvent(e) {
      this.$emit('click', e);
    }
  }
};
</script>
