<template>
  <filter-form v-bind="state.filter.schema" />
</template>

<script>
import Component from 'vue-class-component';
import FilterForm from '../filter-form/filter-form';

@Component({
  name: 'videos-filter',
  components: { FilterForm }
})
export default class VideosFilter extends Component {
  get state() {
    return this.$store.state.videos;
  }
}
</script>
