<template>
  <div class="content">
    <h4>UI Guide, version 0.1</h4>
    <p>Здесь собраны основные компоненты UI, и показана их возможный функционал.</p>

    <h4>Используется:</h4>
    <p>
      1. Vue 2.3<br />
      2. Element-UI 1.3.7<br />
      3. Кастомный Element-UI Theme - CSS ( мы модифицируем переменные и перегенерируем сборку CSS )<br />
      4. Внутренние стили для разметки Stylus -> Css
    </p>

    <h4>Требования к окружению:</h4>
    <p>
      1. Современный браузер IE 11+, Chrome, FireFox<br />
      2. Установленный flash player<br />
    </p>
  </div>
</template>

<script>
export default {
  name: 'hello'
};
</script>

<style scoped></style>
