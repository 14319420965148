






import { Prop, Component, Vue } from 'vue-property-decorator';
import { TableComponentNames } from '@/components/tables/components/names';
import { IAreaRecord } from '@/store/areas/records';
import { formatDateTime } from '@/apps/common/filters';

@Component({
  name: TableComponentNames.AreaRecordDescription
})
export default class TableGeneratorAreaRecordDescription extends Vue {
  @Prop({ required: true })
  item!: IAreaRecord;

  computeFormattedDate(date) {
    return formatDateTime(date);
  }
}
