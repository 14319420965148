import { generateV4UUID } from './uuid';

const UUIDLocalStoragePropertyName = 'uuid';

function getDefaultProperty(tag, prop) {
  const element = document.createElement(tag);
  document.body.appendChild(element);
  const value = getComputedStyle(element)[prop];
  document.body.removeChild(element);
  return value;
}

export default {
  state: {
    languages: ['en', 'ru'],
    token: '',
    uuid: generateUserAgentUUID(),
    timestamp: 0,
    acceptLanguage: '',
    menu: {
      parent: null,
      child: null,
      collapsed: false
    },
    filter: {
      collapsed: false
    },
    key: {
      ctrl: false
    },
    configured: false,
    debug: false,
    fileHandler: null,
    permissions: {
      sound: {
        enabled: true,
        error: null
      }
    }
  },
  getters: {
    isBrowserOrientationOld() {
      return getDefaultProperty('img', 'image-orientation') !== 'from-image';
    }
  }
};

function generateUserAgentUUID() {
  return localStorage[UUIDLocalStoragePropertyName] || (localStorage[UUIDLocalStoragePropertyName] = generateV4UUID());
}
