export default {
  state: {
    loading: false,
    confidence: null,
    items: [],
    limits: [10, 20, 50, 100, 200, 500],
    next_page: '',
    filter: {
      empty: null,
      current: null
    }
  },
  actions: {
    verify
  }
};

function verify({ state, rootState, dispatch }, payload) {
  state.loading = true;
  state.confidence = null;

  return dispatch('requestApi', { model: 'verify', method: 'get', filter: payload })
    .then((v) => {
      state.loading = false;
      state.confidence = (v && v.confidence) || 0;
      return v;
    })
    .catch((e) => {
      state.loading = false;
      return Promise.reject(e);
    });
}
