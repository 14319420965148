<template>
  <div @click="refreshScreenshot" :style="{ cursor: !loading && refresh ? 'pointer' : 'default' }">
    <div class="flex flex--main-center flex--cross-center" :style="templateScreenshotStyle" v-if="state === 'loading' || state === 'error'">
      <span>
        <i class="el-icon-loading" v-show="state === 'loading'" style="font-size: 3rem"></i>
        <i class="el-icon-close" v-show="state === 'error'" style="font-size: 3rem"></i>
      </span>
    </div>
    <img ref="img" v-show="state === 'loaded'" :style="imageScreenshotStyle" />
  </div>
</template>

<script>
import axios from 'axios';

const CancelToken = axios.CancelToken;

const minHeight = '90px',
  maxHeight = '480px',
  maxWidth = '640px';

export default {
  name: 'camera-screenshot',
  props: {
    refresh: {
      type: Boolean
    },
    formContent: {
      type: Object
    },
    screenshot: {
      type: String
    },
    displayWidth: {
      type: String,
      default: '160px'
    },
    width: {
      type: Number,
      default: 160
    },
    height: {
      type: Number,
      default: 0
    },
    align: {
      type: String,
      default: 'left'
    }
  },
  data: function () {
    return {
      state: 'none',
      sourceCancelToken: null
    };
  },
  computed: {
    loading() {
      return this.state === 'loading';
    },
    templateScreenshotStyle() {
      return {
        width: this.displayWidth,
        border: '1px solid #efefef',
        minHeight: minHeight,
        maxWidth: maxWidth,
        maxHeight: maxHeight
      };
    },
    imageScreenshotStyle() {
      return {
        margin: this.align === 'center' ? '0 auto' : '0',
        display: 'block',
        maxHeight: this.width > 0 ? this.width + 'px' : maxHeight,
        maxWidth: this.width > 0 ? this.width + 'px' : maxWidth
      };
    }
  },
  mounted() {
    this.load();
  },
  beforeDestroy() {
    this.tryCancelRequest();
  },
  watch: {
    screenshot: function () {
      this.load();
    }
  },
  methods: {
    getUrl() {
      return this.screenshot + (this.width ? '?width=' + this.width : '') + (this.height ? '&height=' + this.height : '');
    },
    tryCancelRequest() {
      if (!this.sourceCancelToken) return;

      try {
        console.log('Try cancel request', this.screenshot);
        this.sourceCancelToken.cancel();
        this.sourceCancelToken = null;
      } catch (e) {
        console.warn('[axios.cancel] error ', e);
      }
    },
    load() {
      this.state = 'loading';

      this.tryCancelRequest();
      this.sourceCancelToken = CancelToken.source();

      return axios({
        url: this.getUrl(),
        responseType: 'blob',
        headers: {
          Authorization: 'Token ' + encodeURIComponent(this.$store.state.app.token || (window.api && window.api.token))
        },
        cancelToken: this.sourceCancelToken.token
      })
        .then((v) => {
          if (v.data && v.data.size) {
            this.$refs.img.src = URL.createObjectURL(v.data);
            this.formContent && (this.formContent.image = this.$refs.img.src);
            this.state = 'loaded';
          } else {
            this.state = 'error';
          }
        })
        .catch((e) => {
          function asyncReadResponseText(data, cb) {
            const r = new FileReader();
            r.onload = () => {
              cb(r.result);
            };
            r.onerror = () => {
              cb(data);
            };
            r.readAsText(data, 'utf-8');
          }

          this.state = 'error';

          if (e.response) {
            asyncReadResponseText(e.response.data, (r) => {
              console.warn('[camera:screenshot] error ', r);
            });
          } else if (e.config.method) {
            const { method, url } = e.config;
            console.warn('[camera:screenshot] error\n' + `method: ${method.toUpperCase()}\n` + `url: ${url}`);
          } else {
            console.warn('[camera:screenshot] error ', e);
          }
        })
        .finally(() => {
          this.sourceCancelToken = null;
        });
    },
    setImage(data) {
      this.$refs.img.src = URL.createObjectURL(data);
      this.formContent && (this.formContent.image = this.$refs.img.src);
    },
    refreshScreenshot() {
      if (!this.refresh) return;
      this.state = 'loading';
      this.$store
        .dispatch('refreshScreenshot', this.getUrl())
        .then((v) => {
          this.setImage(v);
          this.state = 'loaded';
        })
        .catch((e) => {
          this.state = 'error';
          console.warn('[camera:screenshot] renew error ', e);
        });
    }
  }
};
</script>
