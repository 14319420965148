import getCrud from '../get.crud';
import _ from '@/apps/common/lodash';

const emptyFilter = Object.seal({
    id: '',
    page: '',
    name_contains: '',
    claim_id: '',
    ordering: null,
    limit: 10,
    created_date_gte: null,
    created_date_lte: null,
    claim_creation_date_gte: null,
    claim_creation_date_lte: null,
    incident_date_lte: null,
    incident_date_gte: null,
    active: '',
    threshold: 0.75,
    looks_like: ''
  }),
  empty = Object.seal({
    id: null,
    active: true,
    name: '',
    comment: '',
    claim_id: '',
    claim_creation_date: null,
    incident_date: null,
    face_count: 0,
    has_faces: false,
    created_date: null,
    modified_date: null
  }),
  Name = 'cases',
  Action = require('../action.names').default.Cases;

export default {
  state: {
    loading: false,
    page: '',
    prev_page: [],
    next_page: null,
    limits: [10, 20, 50, 100, 200, 500],
    items: [],
    item: {
      empty
    },
    filter: {
      empty: emptyFilter,
      current: _.cloneDeep(emptyFilter)
    },
    Action
  },
  actions: getCrud(Name)
};
