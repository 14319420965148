import Vue from 'vue';

const Name = 'cryptopro';

export default {
  state: {
    Name,
    page: 0,
    next_page: null,
    prev_page: null,
    loading: false,
    items: [],
    selectedIndex: 0,
    next: null
  },
  actions: {
    getCertificates,
    getChallenge,
    loginWithCryptopro,
    setCurrentUserCertificate,
    setUserCertificate,
    getUserCertificate,
    deleteUserCertificate
  }
};

function getCertificates({ state, dispatch }, payload) {
  return Vue.prototype.$cryptopro.getCertificates().then((v) => {
    state.items = v;
    return v;
  });
}

function deleteUserCertificate({ state, dispatch }, payload) {
  const user = payload;
  return dispatch('requestApi', { model: 'cproauth/user-keys', id: user.id, method: 'delete' });
}

function getUserCertificate({ state, dispatch }, payload) {
  const user = payload;
  return dispatch('requestApi', { model: 'cproauth/user-keys', id: user.id, method: 'get' });
}

function getChallenge({ state, dispatch }, payload) {
  return dispatch('requestApi', {
    model: 'cproauth/auth',
    action: 'request-challenge',
    removeLast: true,
    method: 'post'
  });
}

function setCurrentUserCertificate({ state, dispatch }, payload) {
  return dispatch('getChallenge')
    .then((v) => {
      const certificate = state.items[state.selectedIndex];
      return Vue.prototype.$cryptopro.sign(v.challenge, certificate && certificate.instance, true);
    })
    .then((v) => {
      return dispatch('requestApi', {
        model: 'cproauth/auth',
        action: 'register-key',
        method: 'post',
        removeLast: true,
        data: { signed_challenge: v }
      });
    });
}

function setUserCertificate({ state, dispatch }, payload) {
  const certificate = state.items[state.selectedIndex],
    user = payload;
  return dispatch('requestApi', {
    model: 'cproauth/user-keys',
    method: 'post',
    data: { user: user.id, thumbprint: certificate.thumbprint }
  });
}

function loginWithCryptopro({ state, dispatch }, payload) {
  return dispatch('getChallenge')
    .then((v) => {
      const certificate = state.items[state.selectedIndex];
      return Vue.prototype.$cryptopro.sign(v.challenge, certificate && certificate.instance, true);
    })
    .then((v) => {
      return dispatch('requestApi', {
        model: 'cproauth/auth',
        action: 'login',
        method: 'post',
        removeLast: true,
        data: { signed_challenge: v }
      });
    })
    .then((v) => {
      return { access: JSON.stringify(v) };
    });
}
