<template>
  <filter-form v-bind="state.filter.schema" />
</template>

<script>
import FilterForm from '../filter-form/filter-form';

export default {
  name: 'audit-logs-filter',
  components: { FilterForm },
  computed: {
    state: function () {
      return this.$store.state.audit_logs;
    }
  }
};
</script>
