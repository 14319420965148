<template>
  <div class="confidence-label">
    <el-tooltip :content="getItemLabel(currentItem)" placement="top" v-if="confidenceDisplay">
      <div class="items-container">
        <span class="item" :key="key" v-for="(item, key) in items" :style="{ backgroundColor: currentItem.color }"></span>
      </div>
    </el-tooltip>
    <span v-else>{{ value }}</span>
  </div>
</template>
<script>
import Component from 'vue-class-component';

@Component({
  name: 'confidence-label',
  props: {
    objects: {
      type: String,
      default: 'faces'
    },
    value: {
      type: Number,
      required: true
    }
  }
})
export default class ConfidenceLabel extends Component {
  getItemLabel({ label }) {
    const locale = this.$i18n.locale,
      labelIsObject = label && typeof label === 'object';
    return labelIsObject ? label[locale] || label.en || label.ru : label;
  }

  get currentItem() {
    return this.items[this.items.length - 1];
  }

  get items() {
    const matchedIndex = this.confidenceDisplay.findIndex((v) => this.value < v.confidence),
      currentValueItemsCount = matchedIndex === -1 ? this.confidenceDisplay.length : Math.max(matchedIndex, 1);
    return this.confidenceDisplay.slice(0, currentValueItemsCount);
  }

  get confidenceDisplay() {
    return this.$store.getters.getConfidenceDisplay(this.objects);
  }
}
</script>

<style lang="stylus">

.confidence-label {
  .items-container {
    display: inline-block;
  }

  .item {
    display: inline-block;
    width: 0.5rem
    height: 0.5rem
    border-radius: 50%;
    margin-right: 0.2rem;
  }
}
</style>
