















import Component from 'vue-class-component';
import Vue from 'vue';
import { TableComponentNames } from '@/components/tables/components/names';
import { Prop } from 'vue-property-decorator';

@Component({
  name: TableComponentNames.EventTimeCamera
})
export default class TableGeneratorEventTimeCamera extends Vue {
  @Prop({ type: Object, required: true })
  state!: any;

  @Prop({ type: Object, required: true })
  item!: any;

  @Prop({ type: String, required: true })
  objectsType!: string;

  getCamera(item) {
    return this.$store.state.cameras.items.find((v) => v.id === item.camera) || { name: this.$tf('camera | not_found,,1') };
  }

  getCameraGroup(item) {
    return this.$store.state.camera_groups.items.find((v) => v.id === item.camera_group) || { name: '' };
  }

  getItemLink(item) {
    return `/events/${this.objectsType}/${encodeURIComponent(item.id)}/`;
  }

  addArrayFilter(name, id) {
    if (this.state.filter.current[name].indexOf(id) === -1) this.state.filter.current[name].push(id);
  }
}
