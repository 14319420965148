import getCrud from '../get.crud';
import _ from '@/apps/common/lodash';

const emptyFilter = Object.seal({
    id: '',
    page: '',
    ordering: null,
    limit: 10,
    id_in: null,
    id_gte: '',
    case_in: null,
    video_archive_in: null,
    created_date_gte: null,
    created_date_lte: null,
    looks_like: ''
  }),
  empty = Object.seal({
    id: null,
    case: 0,
    video_archive: 0,
    thumbnail: '',
    source_photo_name: ''
  }),
  Name = 'case-faces',
  Action = require('../action.names').default.CaseFaces;

export default {
  state: {
    loading: false,
    page: '',
    prev_page: [],
    next_page: null,
    limits: [10, 20, 50, 100, 200, 500],
    items: [],
    item: {
      empty
    },
    filter: {
      empty: emptyFilter,
      current: _.cloneDeep(emptyFilter)
    },
    Action
  },
  actions: getCrud(Name)
};
