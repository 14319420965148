import qs from 'qs';
import _ from '@/apps/common/lodash';

function alphabeticalSort(a, b) {
  return a.localeCompare(b);
}

const FilterMixin = {
  watch: {
    '$route.params.filter': function (v, p) {
      this.applyQueryFilter(v);
      if (!this.state.filter.current.page && this.state.prev_page.length) {
        this.state.prev_page = [];
      }
      this.loadItems();
    },
    'state.filter.current': {
      deep: true,
      handler: function (v, p) {
        let filter = _.pickBy(v, (v) => !!v),
          filterString = qs.stringify(filter, { sort: alphabeticalSort });

        if (this.$route.params.filter !== filterString) {
          this.$router.push({ path: '/' + (this.route || this.model) + '/filter/' + filterString });
          window.localStorage[this.model + '.filter.current'] = filterString;
        }
      }
    }
  },
  mounted() {
    let currentFilter = this.$route.params.filter,
      defaultFilter = qs.stringify(
        _.pickBy(this.state.filter.empty, (v) => v),
        { sort: alphabeticalSort }
      ),
      storedFilterString = this.disableStore ? null : decodeURIComponent(window.localStorage[this.model + '.filter.current']);

    if (currentFilter === defaultFilter && storedFilterString && storedFilterString !== currentFilter) {
      this.$router.push({ path: '/' + (this.route || this.model) + '/filter/' + storedFilterString });
    } else {
      this.applyQueryFilter(currentFilter);
    }
  },
  methods: {
    formatValue(value, multiple = false, identity = (v) => v) {
      let r = null;
      if (multiple) {
        r = value ? (Array.isArray(value) ? value : [value]).map(identity) : [];
      } else {
        r = identity(value);
      }
      return r;
    },
    applyQueryFilter(v) {
      let parsed = qs.parse(v, { arrayLimit: 100 }),
        objectFilter = Object.assign(_.cloneDeep(this.state.filter.empty), parsed),
        filtersEqual = false,
        paramsSchema = this.getParamsSchema();

      if (paramsSchema) {
        Object.keys(paramsSchema).forEach((name) => {
          let schema = paramsSchema[name],
            value = this.formatValue(objectFilter[name], schema.multiple, schema.identity);
          objectFilter[name] = value;
        });
      } else {
        objectFilter['limit'] = parseInt(objectFilter['limit']);
      }

      filtersEqual = _.isEqual(this.state.filter.current, objectFilter);

      if (!filtersEqual) {
        this.state.filter.current = objectFilter;
        window.localStorage[this.model + '.filter.current'] = qs.stringify(
          _.pickBy(objectFilter, (v, k) => k !== 'page' && v),
          { sort: alphabeticalSort }
        );
      }

      this.state.filter.equal = !_.isEqual(this.state.filter.current, this.state.filter.empty);
      return !filtersEqual;
    }
  }
};

export default FilterMixin;
