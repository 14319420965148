




import { Component, Vue } from 'vue-property-decorator';
import FilterForm from '@/components/filter-form/filter-form.vue';
import { blocklistRecordsModule } from '@/store/index';
import { BlocklistRecordsModule } from '@/store/sessions/blocklist-records';

@Component({
  name: 'blocklist-records-filter',
  components: { FilterForm }
})
export default class AreasFilter extends Vue {
  get state(): BlocklistRecordsModule {
    return blocklistRecordsModule;
  }
}
