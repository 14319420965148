import { shortString } from '@/apps/common/filters';
import _ from '@/apps/common/lodash';
import getCrud from '../get.crud';

function getTimezoneString() {
  const tzo = -new Date().getTimezoneOffset(),
    dif = tzo >= 0 ? '+' : '-',
    pad = function padImpl(num) {
      const norm = Math.floor(Math.abs(num));
      return (norm < 10 ? '0' : '') + norm;
    };
  return dif + pad(tzo / 60) + ':' + pad(tzo % 60);
}

const emptyFilter = Object.seal({
    created_date_gte: null,
    created_date_lte: null,
    age_gte: null,
    age_lte: null,
    watch_lists: [],
    cameras: [],
    gender: null,
    precision: '1d',
    return_interval: '1h',
    returns_number: 1,
    chart_types: null // ['visitors', 'gender', 'age', 'cameras', 'visits']
  }),
  Name = 'kyc',
  Action = require('../action.names').default.Kyc;
const emptyItem = { name: '', data: {}, active: true, loading: false };

const items = [
  { ..._.cloneDeep(emptyItem), name: 'visitors' },
  { ..._.cloneDeep(emptyItem), name: 'gender', opacity: 0.6 },
  { ..._.cloneDeep(emptyItem), name: 'age', title: 'average_age' },
  { ..._.cloneDeep(emptyItem), name: 'visits', active: false },
  {
    ..._.cloneDeep(emptyItem),
    name: 'camera',
    title: 'cameras',
    component: 'bar-chart'
  }
];

export default {
  state: {
    loading: false,
    loaded: false,
    page: '',
    next_page: null,
    limits: [10, 20, 50, 100, 200, 500],
    prev_page: [],
    chart_types: ['visitors', 'gender', 'age', 'cameras', 'visits'],
    precision: ['1m', '1h', '1d', '1M', '1y'],
    gender: [null, 'male', 'female'],
    precision_to_return_interval: {
      '1m': 1,
      '1h': 3,
      '1d': 7,
      '1M': 9,
      '1y': 11
    },
    return_interval: ['30s', '1m', '30m', '1h', '3h', '6h', '12h', '1d', '7d', '1M', '3M', '1y'],
    returns_number: [1, 2, 3, 4, 5],
    filter: {
      empty: emptyFilter,
      current: _.cloneDeep(emptyFilter)
    },
    items,
    selectedId: -1,
    Action,
    DateIntervalToI18n: {
      '30s': ['date.second', 30],
      '1m': ['date.minute', 1],
      '30m': ['date.minute', 30],
      '1h': ['date.hour', 1],
      '3h': ['date.hour', 3],
      '6h': ['date.hour', 6],
      '12h': ['date.hour', 12],
      '1d': ['date.day', 1],
      '7d': ['date.day', 7],
      '1M': ['date.month', 1],
      '3M': ['date.month', 3],
      '1y': ['date.year', 1]
    }
  },
  actions: { ...getCrud(Name), get_kyc_items }
};

async function get_kyc_items({ state, dispatch }) {
  const f = state.filter.current;

  loadAreaData('visitors');
  loadAreaData('age');
  loadCameras();
  loadDoubleAreaData('gender');

  if (state.items[3].active) loadDoubleAreaData('visits');

  async function loadAreaData(name) {
    const item = getChartItemByName(name);
    item.loading = true;

    const filter = { ...f, chart_types: name, timezone: getTimezoneString() },
      data = await dispatch(state.Action.Get, { filter }).catch((x) => console.warn(x)),
      xAxisData = data[name].axis1.values,
      yAxisNames = data[name].axis2.values || [name],
      yAxisData = [data[name].counts],
      { total, average, overall } = data[name];
    item.loading = false;
    item.data = {
      xAxisData,
      yAxisData,
      yAxisNames,
      name,
      total,
      average,
      overall
    };
    return true;
  }

  async function loadDoubleAreaData(name) {
    const item = getChartItemByName(name);
    item.loading = true;

    const filter = { ...f, chart_types: name, timezone: getTimezoneString() },
      data = await dispatch(state.Action.Get, { filter }).catch((x) => console.warn(x)),
      xAxisData = data[name].axis1.values,
      yAxisData = typeof data[name].counts[0][0] === 'object' ? data[name].counts.map((count) => count.map(({ percent }) => percent)) : data[name].counts,
      counterData = typeof data[name].counts[0][0] === 'object' ? data[name].counts.map((item) => item.map(({ count }) => count)) : null,
      yAxisNames = data[name].axis2.values,
      overall = typeof data[name].overall[0] === 'object' ? data[name].overall.map(({ percent }) => percent) : data[name].overall,
      { total, average } = data[name];

    item.loading = false;
    item.data = {
      xAxisData,
      yAxisData,
      counterData,
      yAxisNames,
      name,
      total,
      average,
      overall
    };
    return true;
  }

  async function loadCameras() {
    const item = getChartItemByName('camera');
    item.loading = true;

    const filter = {
        ...f,
        chart_types: 'cameras',
        timezone: getTimezoneString()
      },
      camerasData = await dispatch(state.Action.Get, { filter }).catch((x) => console.warn(x)),
      xAxisData = [[], []],
      yAxisData = [],
      id = [],
      name = 'cameras';
    camerasData.cameras.forEach((cam) => {
      yAxisData.unshift(shortString(cam.camera__name));
      xAxisData[0].unshift(cam.count_unique);
      xAxisData[1].unshift(cam.count_total);
      id.unshift(cam.camera_id);
    });
    item.loading = false;
    item.data = { xAxisData, yAxisData, name, id };
    return true;
  }

  function getChartItemByName(name) {
    return state.items.find((v) => v.name === name);
  }
}

/*
const makeGetDate = (precision = '1d') => date => {
  date = new Date(date)
  const minute = (date.getMinutes()).toString().padStart(2, '0')
  const hour = date.getHours().toString().padStart(2, '0')
  const day = (date.getDate()).toString().padStart(2, '0')
  const month = (date.getMonth() + 1).toString().padStart(2, '0')
  const year = date.getFullYear()
  switch (precision) {
    case '1m':
    case '1h':
      return `${hour}:${minute}\n${day}.${month}`
    case '1d':
      return `${day}.${month}\n${year}`
  }
}
*/
