<template>
  <el-button-group size="small">
    <el-button name="first-btn" plain size="small" @click="firstPage" icon="el-icon-d-arrow-left" :disabled="!state.prev_page.length"></el-button>
    <el-button name="prev-btn" plain size="small" @click="prevPage" icon="el-icon-arrow-left" :disabled="state.loading || !state.prev_page.length"></el-button>
    <el-button name="reload-btn" plain size="small" @click="reloadPage" :disabled="state.loading"
      ><span>{{ $tf('common.page') }} {{ state.prev_page.length + 1 }}</span
      ><i class="fa fa-refresh mar-left--0-5"></i
    ></el-button>
    <el-button name="next-btn" plain size="small" @click="nextPage" icon="el-icon-arrow-right" :disabled="state.loading || !state.next_page"></el-button>
  </el-button-group>
</template>

<script>
import qs from 'qs';
import _ from '@/apps/common/lodash';

export default {
  name: 'table-navigation',
  props: {
    state: {
      type: Object,
      required: true
    },
    changeHandler: {
      type: Function,
      required: false
    }
  },
  methods: {
    setPage(value) {
      let filterFromPageQuery = qs.parse(value.split('?')[1] || '', { arrayLimit: 100 }),
        filter = Object.assign(_.cloneDeep(this.state.filter.empty), filterFromPageQuery);

      this.state.page = value;

      if (!value) {
        this.state.filter.current.page = '';
      } else {
        this.state.filter.current.page = filter.page;
      }
      if (this.changeHandler) this.changeHandler();
    },
    firstPage() {
      this.state.prev_page = [];
      this.state.next_page = null;
      this.setPage('');
    },
    prevPage() {
      let page = this.state.prev_page.pop() || '';
      this.setPage(page);
    },
    nextPage() {
      this.state.prev_page.push(this.state.page);
      this.setPage(this.state.next_page);
    },
    reloadPage() {
      if (this.changeHandler) {
        this.changeHandler();
      } else {
        if (this.state.get) {
          this.state.get();
        } else {
          this.$store.dispatch(this.state.Action.Get);
        }
      }
    }
  }
};
</script>
