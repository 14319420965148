




import { Component, Vue } from 'vue-property-decorator';
import FilterForm from '@/components/filter-form/filter-form.vue';
import { AreaTriggersModule } from '@/store/areas/triggers';
import { areaTriggersModule } from '@/store';

@Component({
  name: 'area-triggers-filter',
  components: { FilterForm }
})
export default class AreaTriggersFilter extends Vue {
  get state(): AreaTriggersModule {
    return areaTriggersModule;
  }
}
