import * as filters from '@/apps/common/filters';
import { getBatch } from '@/apps/common/interval_downloader';
import _ from '@/apps/common/lodash';
import getCrud from '../get.crud';

const emptyFilter = Object.seal({
    id: '',
    page: '',
    person_in: null,
    limit: 10,
    created_date_gte: null,
    created_date_lte: null,
    cameras: [],
    camera_groups: [],
    events_count_gte: null,
    gender: [],
    age_gte: null,
    age_lte: null,
    emotions: [],
    glasses: [],
    beard: [],
    liveness: [],
    race: [],
    medmask: []
  }),
  emptyItem = Object.seal({
    id: null,
    open: false,
    event_id: null,
    last_event_date: null,
    events_count: 0,
    matched_event_id: null
  }),
  Name = 'person-events',
  Action = require('../action.names').default.PersonEvents;

export default {
  state: {
    playing: true,
    loading: false,
    page: '',
    next_page: null,
    limits: [10, 20, 50, 100, 200, 500],
    prev_page: [],
    items: [],
    videoWallItems: [],
    item: {
      emptyItem
    },
    eventsByPersonsId: {},
    filter: {
      empty: emptyFilter,
      current: _.cloneDeep(emptyFilter),
      videoWall: _.cloneDeep(emptyFilter)
    },
    Action
  },
  actions: Object.assign({ satisfy_persons_filter, getBatchPersonEvents }, getCrud(Name))
};

function getBatchPersonEvents(context, payload) {
  return getBatch(context, { model: Name, payload });
}

function satisfy_persons_filter({ state, rootState }, { persons, filterName }) {
  let filter = state.filter[filterName || 'current'],
    cameras = rootState.cameras.items,
    satisfy = true,
    event = persons.last_event;

  if (satisfy && filter.id) {
    satisfy = filter.id === persons.id;
  }

  if (satisfy && filter.matched_dossier) {
    satisfy = filter.matched_dossier === (event.matched_dossier || '').toString();
  }

  if (satisfy && filter.acknowledged) {
    satisfy = filter.acknowledged === 'True' ? event.acknowledged : !event.acknowledged;
  }

  if (satisfy && filter.no_match) {
    satisfy = filter.no_match === 'True' ? !event.matched_dossier : !!event.matched_dossier;
  }

  if (satisfy && filter.matched_lists.length) {
    satisfy = _.intersection(filter.matched_lists, event.matched_lists).length > 0;
  }

  if (satisfy && filter.camera_groups.length) {
    let camera = cameras.find((v) => v.id === event.camera),
      group = filter.camera_groups.find((v) => v === camera.group);

    satisfy = !!group;
  }

  if (satisfy && filter.cameras.length) {
    satisfy = !!filter.cameras.find((v) => v === event.camera);
  }

  if (satisfy && filter.created_date_gte) {
    let isoStringToDate = filters.isoStringToDate,
      filterGTEDate = isoStringToDate(filter.created_date_gte),
      eventDate = isoStringToDate(event.created_date);

    satisfy = eventDate.getTime() >= filterGTEDate.getTime();
  }

  if (satisfy && filter.created_date_lte) {
    let isoStringToDate = filters.isoStringToDate,
      filterLteDate = isoStringToDate(filter.created_date_lte),
      eventDate = isoStringToDate(event.created_date);

    satisfy = eventDate.getTime() <= filterLteDate.getTime();
  }

  if (satisfy && filter.gender.length) {
    let r = _.get(event, 'features.gender.name'),
      item = filter.gender.find((v) => v === r);
    satisfy = !!item;
  }

  if (satisfy && filter.age_gte) {
    satisfy = event.features.age >= filter.age_gte;
  }

  if (satisfy && filter.age_lte) {
    satisfy = event.features.age <= filter.age_lte;
  }

  if (satisfy && filter.emotions.length) {
    let r = _.get(event, 'features.emotions.name'),
      item = filter.emotions.find((v) => v === r);
    satisfy = !!item;
  }

  if (satisfy && filter.glasses.length) {
    let r = _.get(event, 'features.glasses.name'),
      item = filter.glasses.find((v) => v === r);
    satisfy = !!item;
  }

  if (satisfy && filter.beard.length) {
    let r = _.get(event, 'features.beard.name'),
      item = filter.beard.find((v) => v === r);
    satisfy = !!item;
  }

  if (satisfy && filter.liveness.length) {
    let r = _.get(event, 'features.liveness.name'),
      item = filter.liveness.find((v) => v === r);
    satisfy = !!item;
  }

  if (satisfy && filter.race.length) {
    let r = _.get(event, 'features.race.name'),
      item = filter.race.find((v) => v === r);
    satisfy = !!item;
  }

  return satisfy;
}
