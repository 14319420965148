const pluralizationRules = {
  /**
   * @param choice {number} a choice index given by the input to $tc: `$tc('path.to.rule', choiceIndex)`
   * @param choicesLength {number} an overall amount of available choices
   * @returns a final choice index to select plural word by
   */
  ru: function getChoiceIndexForRussian(choice, choicesLength) {
    // this === VueI18n instance, so the locale property also exists here

    const teen = choice > 10 && choice < 20;
    const endsWithOne = choice % 10 === 1;
    const zero = choice === 0;
    const one = !teen && endsWithOne;
    const twoToFour = !teen && choice % 10 >= 2 && choice % 10 <= 4;

    let index;
    if (choicesLength === 3) {
      index = (function getIndexForThreeChoices() {
        if (one) return 0;
        if (twoToFour) return 1;
        return 2;
      })();
    } else if (choicesLength === 4) {
      index = (function getIndexForFourChoices() {
        if (zero) return 0;
        if (one) return 1;
        if (twoToFour) return 2;
        return 3;
      })();
    } else console.warn('Incorrect number of Russian case choices');

    return index;
  }
};

export default pluralizationRules;
