export function isObject(candidate) {
  return candidate != null && typeof candidate === 'object';
}

export function isString(candidate) {
  return candidate != null && typeof candidate === 'string';
}

export function isFunction(candidate) {
  return candidate != null && typeof candidate === 'function';
}

export function isErrorResponse(candidate) {
  return isObject(candidate) && isString(candidate.code) && isString(candidate.desc);
}
