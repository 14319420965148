<template>
  <el-date-picker @input="handleInputEvent" :placeholder="placeholder" :clearable="false" :value="model" align="right" :type="type" />
</template>

<script>
import { setDateEndOfDayTime } from '@/store/kyc/kyc.helpers.js';
import { Component } from 'vue-property-decorator';
import BaseFormComponent from './component';

@Component({
  props: {
    type: { type: String, default: 'date' },
    endOfDay: { type: Boolean, default: false }
  }
})
export default class BaseFormComponentDateTime extends BaseFormComponent {
  handleInputEvent(value) {
    this.model = value && this.endOfDay ? setDateEndOfDayTime(value) : value;
    this.clearLinkedFields();
  }
}
</script>
