import Vue from 'vue';
import installCadesPlugin from '../external/cryptopro/cadesplugin_api';

const CryptoProVars = {
  store: 'CAdESCOM.Store',
  signer: 'CAdESCOM.CPSigner',
  attribute: 'CADESCOM.CPAttribute',
  signedData: 'CAdESCOM.CadesSignedData'
};

async function getCryptopro() {
  installCadesPlugin();
  let cadesplugin = window && window.cadesplugin,
    hasPlugin = await cadesplugin.then(() => true).catch(() => false);

  async function getCertificates() {
    let store = await cadesplugin.CreateObjectAsync(CryptoProVars.store),
      storeCertificates,
      certificates = [],
      count = 0;

    store.Open();
    storeCertificates = await store.Certificates;
    count = await storeCertificates.Count;

    for (let i = 1; i <= count; i++) {
      try {
        let currentCertificate = await storeCertificates.Item(i),
          certificate = {
            name: '',
            instance: null,
            validFrom: null,
            validTo: null,
            publicKey: null,
            publicKeyValue: null
          };

        certificate.index = i;
        certificate.instance = currentCertificate;
        certificate.validFrom = new Date(await currentCertificate.ValidFromDate);
        certificate.validTo = new Date(await currentCertificate.ValidToDate);
        /*
        certificate.hasPrivateKey = await currentCertificate.HasPrivateKey()
        certificate.publicKey = await currentCertificate.PublicKey()
        certificate.publicKeyValue = await (await certificate.publicKey.EncodedKey).Value()
        */
        certificate.valid = await (await currentCertificate.IsValid()).Result;
        certificate.thumbprint = await currentCertificate.Thumbprint;
        certificate.name = await currentCertificate.SubjectName;
        certificate.issuer = await currentCertificate.IssuerName;
        certificates.push(certificate);
      } catch (e) {
        console.error('[crypto-pro] add certificate error ', e);
      }
    }

    store.Close();
    return certificates;
  }

  async function sign(data, certificate, dataInBase64) {
    try {
      let signerInstance = await cadesplugin.CreateObjectAsync(CryptoProVars.signer),
        signedDataInstance = await cadesplugin.CreateObjectAsync(CryptoProVars.signedData),
        result;

      await signerInstance.propset_Certificate(certificate);
      await signerInstance.propset_Options(cadesplugin.CAPICOM_CERTIFICATE_INCLUDE_WHOLE_CHAIN);
      await signedDataInstance.propset_ContentEncoding(cadesplugin.CADESCOM_BASE64_TO_BINARY);
      await signedDataInstance.propset_Content(dataInBase64 ? data : btoa(data));
      result = signedDataInstance.SignCades(signerInstance, cadesplugin.CADESCOM_CADES_BES);
      return result;
    } catch (e) {
      console.error('[cryptopro] error sign ', e);
      return null;
    }
  }

  if (hasPlugin) {
    cadesplugin.set_log_level(cadesplugin.LOG_LEVEL_DEBUG);
  }

  return {
    cadesplugin,
    version: cadesplugin.JSModuleVersion,
    hasPlugin,
    getCertificates,
    sign,
    getLastError: () => cadesplugin.getLastError()
  };
}

const cryptoproPlugin = {};

cryptoproPlugin.install = function install() {
  Vue.prototype.$cryptoproInstallPromise = new Promise(async function getCrytoProPromise(resolve, reject) {
    try {
      const cryptopro = await getCryptopro();
      Vue.prototype.$cryptopro = cryptopro ?? null;
      resolve(true);
    } catch (e) {
      reject(false);
    }
  });
};

export default cryptoproPlugin;
