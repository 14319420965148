import ActionName from '@/store/action.names';
import { Component, Vue, Watch } from 'vue-property-decorator';
import { computeSessionMonitorConfig, detectTokenState, recordTokenRenewalVideo, TokenState } from './session-monitor-helpers';

@Component
export default class SessionMonitor extends Vue {
  timeout = null;

  render(h) {
    return h();
  }

  get config() {
    return computeSessionMonitorConfig(this.$store.state);
  }

  @Watch('$store.state.users.current.id')
  handleUserIdChange(id, previousId) {
    if (this.config.enabled) {
      this.toggleSchedulerState(id, previousId);
    }
  }

  toggleSchedulerState(id, previousId) {
    if (isUserLoggedIn(id, previousId)) {
      this.scheduleTokenRenewal();
    } else if (isUserLoggedOut(id, previousId)) {
      this.disableTokenRenewal();
    }
  }

  scheduleTokenRenewal() {
    this.timeout = setTimeout(this.attemptTokenRenewal, this.config.intervalInMs);
  }

  disableTokenRenewal() {
    clearTimeout(this.timeout);
    this.timeout = null;
  }

  attemptTokenRenewal() {
    switch (this.detectTokenState()) {
      case TokenState.Valid:
        return this.scheduleTokenRenewal();
      case TokenState.Expired:
        return this.logout();
      case TokenState.ExpiresSoon:
        return this.renewToken();
    }
  }

  detectTokenState() {
    const { expiresAt, intervalInMs, durationInMs } = this.config;
    return detectTokenState(Date.now(), expiresAt, intervalInMs, durationInMs);
  }

  renewToken(attempt = 1) {
    return recordTokenRenewalVideo()
      .then(this.dispatchTokenRenewalAction)
      .then(this.scheduleTokenRenewal)
      .catch(() => this.handleTokenRenewalError(attempt));
  }

  dispatchTokenRenewalAction(video) {
    return this.$store.dispatch(ActionName.Users.RenewToken, video);
  }

  async handleTokenRenewalError(attempt) {
    if (attempt < this.config.attempts) {
      await this.renewToken(attempt + 1);
    } else {
      await this.logout();
    }
  }

  logout() {
    return this.$store.dispatch(ActionName.Users.Logout);
  }
}

function isUserLoggedIn(id, previousId) {
  return id !== 0 && previousId === 0;
}

function isUserLoggedOut(id, previousId) {
  return id === 0 && previousId !== 0;
}
