<template>
  <div id="page">
    <header>
      <div class="content el-row--flex is-justify-space-between is-align-middle">
        <router-link to="/guide/landing" class="logo">
          <img src="../../assets/logo.svg" class="logo-secondary d--middle" />
          <div class="d--middle"><h3>FindFace Multi</h3></div>
        </router-link>

        <el-menu class="header--menu" mode="horizontal">
          <el-menu-item index="1">
            <i class="fa fa-align-justify" aria-hidden="true"></i>
            Gallery management
          </el-menu-item>
          <el-menu-item index="2">
            <i class="fa fa-picture-o" aria-hidden="true"></i>
            Photo processing
          </el-menu-item>
          <el-menu-item index="3">
            <i class="fa fa-video-camera" aria-hidden="true"></i>
            Video processing
          </el-menu-item>
        </el-menu>
      </div>
    </header>

    <main class="pad-v-2">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item :to="{ path: '/' }">home</el-breadcrumb-item>
        <el-breadcrumb-item>photo processing</el-breadcrumb-item>
        <el-breadcrumb-item>batch uploading</el-breadcrumb-item>
      </el-breadcrumb>

      <g-buttons :included="true">
        <div slot="main">xxx --- xxx</div>
      </g-buttons>
    </main>

    <footer class="a--center pad-2">
      <div>Software link</div>
      <div>Documetation link</div>
      <div>Copyrights @XXXX</div>
    </footer>
  </div>
</template>

<script>
import GButtons from '../components/buttons';

let test = {
  a: 1,
  b: 2
};

export default {
  components: { GButtons },
  name: 'secondary',
  data: () => Object.assign(test, { test: test })
};
</script>
