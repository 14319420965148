<template>
  <div id="page">
    <header>
      <div class="mar-v-2 a--center">
        <h1 class="mar-v-2 mar-h-2 inline">
          <img src="../../assets/logo.svg" class="logo-main d--middle" />
          <span class="mar-h-2">Logo big text v 1</span>
        </h1>
      </div>
    </header>

    <main>
      <el-row :gutter="15">
        <el-col :xs="24" :sm="8">
          <router-link to="/guide/secondary">
            <el-button type="text" class="s--full">
              <i class="fa fa-th-large f--large" aria-hidden="true"></i>
              <h2>Gallery management</h2>
            </el-button>
          </router-link>
          <span class="text-regular">You can 1, 2, 3 ...</span>
        </el-col>

        <el-col :xs="24" :sm="8">
          <router-link to="/guide/secondary">
            <el-button type="text" class="s--full">
              <i class="fa fa-picture-o f--large" aria-hidden="true"></i>
              <h2>Photo processing</h2>
            </el-button>
          </router-link>
          <span class="text-regular">You can 1, 2, 3 ...</span>
        </el-col>

        <el-col :xs="24" :sm="8">
          <router-link to="/guide/secondary">
            <el-button type="text" class="s--full">
              <i class="fa fa-video-camera f--large" aria-hidden="true"></i>
              <h2>Video proceesing</h2>
            </el-button>
          </router-link>
          <span class="text-regular">You can 1, 2, 3 ...</span>
        </el-col>
      </el-row>
    </main>

    <footer class="a--center pad-2">
      <div>Software link</div>
      <div>Documetation link</div>
      <div>Copyrights @XXXX</div>
    </footer>
  </div>
</template>

<script>
export default {
  name: 'landing'
};
</script>
