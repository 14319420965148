<template>
  <el-button v-if="!icon" @click.prevent.stop="copyToClipBoard" size="small">{{ $tf('copy_to_clipboard') }}</el-button>
  <el-tooltip v-else class="item" effect="dark" :content="$tf('copy_to_clipboard')" placement="right-end">
    <span class="icon-copy" @click.prevent.stop="copyToClipBoard" />
  </el-tooltip>
</template>

<script>
export default {
  name: 'copyToClipboardButton',
  props: {
    text: String,
    icon: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    copyToClipBoard() {
      if (!navigator.clipboard && !window.isSecureContext) {
        this.fallbackCopyTextToClipboard(this.text);
        return;
      }
      navigator.clipboard.writeText(this.text).then(
        () => {
          this.$notify({ type: 'success', message: this.$tf('clipboard_success') });
        },
        (err) => {
          this.$notify({ type: 'error', message: this.$tf('clipboard_failed') });
        }
      );
    },
    fallbackCopyTextToClipboard(text) {
      const textArea = document.createElement('textarea');
      textArea.value = text;
      textArea.style.top = '-10000px';
      textArea.style.left = '-10000px';
      textArea.style.position = 'fixed';

      document.body.appendChild(textArea);
      textArea.focus();
      textArea.select();

      try {
        document.execCommand('copy');
        this.$notify({ type: 'success', message: this.$tf('clipboard_success') });
      } catch (err) {
        this.$notify({ type: 'error', message: this.$tf('clipboard_failed') });
      }
      document.body.removeChild(textArea);
    }
  }
};
</script>

<style lang="stylus">
.icon-copy {
  width: 16px;
  height: 16px;
  position relative
  display block
  &:before,
  &:after {
    content: '';
    position absolute;
    display block;
    width: 10px;
    height: 10px;
    border: 1px solid #fff;
    border-radius: 3px;
  }
  &:before {
    top: 0;
    right: 0;
  }
  &:after {
    left: 0;
    bottom: 0;
    backdrop-filter: brightness(0.4);
  }
}
</style>
