export const ReportStatus = {
  ERROR: 'error',
  COMPLETED: 'completed',
  SCHEDULED: 'scheduled',
  PROCESSING: 'processing'
};

export const PictureRenderingMode = {
  FULL: 'full',
  LINK: 'link',
  PREVIEW: 'preview'
};
