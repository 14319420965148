







import { Prop, Component, Vue } from 'vue-property-decorator';
import { TableComponentNames } from '@/components/tables/components/names';

@Component({
  name: TableComponentNames.DateTime
})
export default class TableGeneratorDateTime extends Vue {
  @Prop({ required: true })
  item!: any;

  @Prop({ default: 'created-date' })
  prop!: string;

  get value() {
    return this.item[this.prop];
  }
}
