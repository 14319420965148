<template>
  <div class="flex flex--main-center flex--cross-center" :style="templateScreenshotStyle">
    <span>
      <i class="el-icon-close" style="font-size: 3rem"></i>
    </span>
  </div>
</template>

<script>
export default {
  name: 'failedScreenshot',
  computed: {
    templateScreenshotStyle() {
      return {
        border: '1px solid #efefef',
        height: '100%',
        minHeight: '90px'
      };
    }
  }
};
</script>
