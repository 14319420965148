import MockService from '@/definitions/mock.items';
import { IBlockListRecord, IBlockListRecordsFilter } from '@/store/sessions/blocklist-records/index';

export const EmptyItem: IBlockListRecord = {
  id: 0,
  uuid: '',
  reason: '',
  expire_date: null,
  created_date: ''
};

export const EmptyFilter: IBlockListRecordsFilter = {
  uuid_in: [],
  uuid_contains: '',
  created_date_gt: '',
  created_date_gte: '',
  created_date_lt: '',
  created_date_lte: '',
  limit: 10,
  ordering: '',
  reason_contains: '',
  page: undefined
};

export const mockItems: IBlockListRecord[] = [
  {
    ...EmptyItem,
    id: 1,
    uuid: 'uuid-xxx4-5678',
    reason: 'reason is abcdefg',
    expire_date: new Date().toISOString(),
    created_date: new Date().toISOString()
  },
  {
    ...EmptyItem,
    id: 2,
    uuid: 'uuid-1314-5678',
    reason: 'reason is defg1234',
    expire_date: new Date().toISOString(),
    created_date: new Date().toISOString()
  },
  {
    ...EmptyItem,
    id: 3,
    uuid: 'uuid-1234-5678',
    reason: 'reason is xxx',
    expire_date: new Date().toISOString(),
    created_date: new Date().toISOString()
  }
];

export const mockBlocklistRecordsService = new MockService(mockItems);
