<template>
  <div
    ref="container"
    name="object-selector"
    class="object-selector"
    @click="clickHandler"
    @mousemove="moveHandler"
    @mouseleave="leaveHandler"
    v-loading="loading"
  >
    <img class="object-selector--image" :src="visibleImageSrc" :class="`${orientationClass} ${orientationCorrection}`" />
    <canvas ref="canvas" style="position: absolute; left: 0; top: 0; z-index: 100"></canvas>
  </div>
</template>
<script>
import Component from 'vue-class-component';
import { Watch } from 'vue-property-decorator';

const EmptyPng = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII=';

@Component({
  name: 'object-selector',
  components: { ObjectSelector },
  props: {
    image: {
      type: Object,
      required: true
    },
    items: {
      type: Array,
      required: true
    },
    value: {
      type: Number,
      required: true
    },
    itemColor: {
      type: String,
      default: '#0f0'
    },
    disabledItemColor: {
      type: String,
      default: '#ccc'
    }
  }
})
export default class ObjectSelector {
  sourceWidth = 1280;
  sourceHeight = 720;
  error = null;
  loading = false;
  visibleImageSrc = EmptyPng;

  get orientation() {
    return this.image?.orientation || 1;
  }

  get orientationClass() {
    return 'orientation' + this.orientation;
  }

  get orientationCorrection() {
    return this.orientation >= 5 ? 'orientation-correction' : '';
  }

  get calculatedRects() {
    const result = (this.items || []).map((v) => this.calculateRect(this.convertLTRBToXYWH(v.bbox), this.sourceWidth, this.sourceHeight));
    return result;
  }

  get imageUrl() {
    return this.image?.url;
  }

  @Watch('image')
  imageHandler() {
    this.visibleImageSrc = EmptyPng;
    this.clear();
    this.load();
  }

  @Watch('value')
  selectedIndexHandler() {
    this.draw();
  }

  created() {
    this.draw = this.draw.bind(this);
  }

  mounted() {
    window.addEventListener('resize', this.draw);
    this.load();
  }

  destroyed() {
    window.removeEventListener('resize', this.draw);
  }

  load() {
    const image = new Image();
    image.onload = () => this.loadHandler(image);
    image.onerror = this.loadErrorHandler;
    this.loading = true;
    image.src = this.imageUrl;
  }

  loadHandler(image) {
    this.loading = false;
    if (this.orientation >= 5 && this.$store.getters.isBrowserOrientationOld) {
      this.sourceWidth = image.naturalHeight;
      this.sourceHeight = image.naturalWidth;
    } else {
      this.sourceWidth = image.naturalWidth;
      this.sourceHeight = image.naturalHeight;
    }
    this.draw();
    image.onerror = image.onload = null;
    this.visibleImageSrc = this.imageUrl;
  }

  loadErrorHandler() {
    this.loading = false;
    const errorType = 'error.image.load';
    this.error = new Error(errorType);
    this.$emit(errorType);
  }

  getContext() {
    return this.$refs.canvas.getContext('2d');
  }

  draw() {
    this.clear();
    this.resizeCanvasByContainer();
    this.drawItems();
  }

  drawItems() {
    this.items.forEach((v, k) => {
      const { low_quality } = v;
      const drawObjectDefinition = {
        rect: this.calculatedRects[k],
        selected: k === this.value,
        low_quality
      };
      this.drawRect(drawObjectDefinition);
    });
  }

  resizeCanvasByContainer() {
    let rect = this.$refs.container.getBoundingClientRect();
    this.$refs.canvas.width = rect.width;
    this.$refs.canvas.height = rect.height;
  }

  convertLTRBToXYWH(v) {
    const { left, top, right, bottom } = v;
    return {
      x: left,
      y: top,
      w: right - left,
      h: bottom - top
    };
  }

  calculateRect({ x, y, w, h }, sourceWidth, sourceHeight) {
    let containerRect = this.$refs.container.getBoundingClientRect(),
      scaleFactor = Math.min(containerRect.width / sourceWidth, containerRect.height / sourceHeight),
      resultSize = { w: sourceWidth * scaleFactor, h: sourceHeight * scaleFactor },
      resultOffset = { x: (containerRect.width - resultSize.w) / 2, y: (containerRect.height - resultSize.h) / 2 },
      resultRect = {
        x: x * scaleFactor + resultOffset.x,
        y: y * scaleFactor + resultOffset.y,
        w: w * scaleFactor,
        h: h * scaleFactor
      },
      resultRectBySides = {
        left: resultRect.x,
        right: resultRect.x + resultRect.w,
        top: resultRect.y,
        bottom: resultRect.y + resultRect.h
      };
    return { ...resultRect, ...resultRectBySides };
  }

  drawRect({ rect, low_quality, selected }) {
    let ctx = this.getContext();
    ctx.strokeStyle = low_quality ? this.disabledItemColor : this.itemColor;
    ctx.setLineDash(selected ? [] : [8, 8]);
    ctx.lineWidth = 2;
    ctx.shadowColor = '#999';
    ctx.shadowBlur = 2;
    ctx.strokeRect(rect.x, rect.y, rect.w, rect.h);
  }

  clear() {
    this.getContext().clearRect(0, 0, 10000, 10000);
  }

  getItemIndex(e) {
    const x = e.offsetX,
      y = e.offsetY,
      index = this.calculatedRects.findIndex((v) => x >= v.left && x <= v.right && y >= v.top && y <= v.bottom);
    return index;
  }

  moveHandler(e) {
    let hasItem = this.getItemIndex(e) > -1;
    this.$refs.container.style.cursor = hasItem ? 'pointer' : 'default';
  }

  leaveHandler(e) {
    this.$refs.container.style.cursor = 'default';
  }

  clickHandler(e) {
    let itemIndex = this.getItemIndex(e);
    if (~itemIndex) {
      this.$emit('input', itemIndex);
      this.$emit('change', itemIndex);
    } else {
      this.$emit('click', e);
    }
  }
}
</script>

<style lang="stylus">
.object-selector {
  .&--image {
    image-orientation: none;
  }

  .orientation-correction {
    width: 480px;
    height: 100%;
    left: calc(50% - 240px);
  }
}
</style>
