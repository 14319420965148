






import Component from 'vue-class-component';
import Vue from 'vue';
import Features from '../../common/features.vue';
import { TableComponentNames } from '@/components/tables/components/names';
import { Prop } from 'vue-property-decorator';

@Component({
  name: TableComponentNames.EventFeatures,
  components: { Features }
})
export default class TableGeneratorEventFeatures extends Vue {
  @Prop({ type: Object, required: true })
  item!: any;

  @Prop({ type: String, required: true })
  objectsType!: string;
}
