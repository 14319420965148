import common from './ru/common';
import date from './ru/date';
import error from './ru/error';
import features from './ru/features.json';
import parameter from './ru/parameter';

export default {
  features,
  common: common,
  error: error,
  parameter: parameter,
  date: date,
  phrases: {
    no_data: 'нет данных'
  },
  texts: {
    license_agreement: require('./texts/ru/license_agreement.txt').default
  },
  accept_language: 'ru-RU;q=0.9, en;q=0.6, *;q=0.5',
  documentation_language: 'ru'
};
