// import qs from 'qs'
import getCrud from '../../get.crud';
const Name = 'genetec/config';

function check_genetec_config({ state, rootState, dispatch }, payload) {
  return dispatch(rootState.Action.RequestApi, { model: Name + '/check', method: 'POST', data: payload });
}

export default {
  state: {
    filter: {
      empty: {},
      current: {}
    },
    loading: false,
    items: {
      host: '',
      port: '',
      user: '',
      password: '',
      base_uri: '',

      media_host: '',
      media_port: '',
      media_user: '',
      media_password: '',

      alarm_id: '',
      application_id: '',
      configured: false
    }
  },
  actions: Object.assign({ check_genetec_config }, getCrud(Name))
};
