const template = '10000000-1000-4000-8000-100000000000';

export function generateV4UUID() {
  return template.replace(/[018]/g, computeV4UUIDBits);
}

function computeV4UUIDBits(char) {
  return (char ^ (random() & (15 >> (char >> 2)))).toString(16);
}

function random() {
  return crypto.getRandomValues(new Uint8Array(1))[0];
}
