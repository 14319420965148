import common from './en/common';
import date from './en/date';
import error from './en/error';
import features from './en/features.json';
import parameter from './en/parameter';

export default {
  features,
  common: common,
  error: error,
  parameter: parameter,
  date: date,
  phrases: {
    no_data: 'no data'
  },
  texts: {
    license_agreement: require('./texts/en/license_agreement.txt').default
  },
  accept_language: 'en-US;q=0.9, en;q=0.6, *;q=0.5',
  documentation_language: 'en'
};
