import clone from 'lodash/clone';
import cloneDeep from 'lodash/cloneDeep';
import debounce from 'lodash/debounce';
import get from 'lodash/get';
import has from 'lodash/has';
import identity from 'lodash/identity';
import intersection from 'lodash/intersection';
import isArray from 'lodash/isArray';
import isBoolean from 'lodash/isBoolean';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import isNil from 'lodash/isNil';
import isNumber from 'lodash/isNumber';
import isObject from 'lodash/isObject';
import isString from 'lodash/isString';
import map from 'lodash/map';
import merge from 'lodash/merge';
import omit from 'lodash/omit';
import omitBy from 'lodash/omitBy';
import partialRight from 'lodash/partialRight';
import pick from 'lodash/pick';
import pickBy from 'lodash/pickBy';
import random from 'lodash/random';
import range from 'lodash/range';
import reduce from 'lodash/reduce';
import set from 'lodash/set';
import sortBy from 'lodash/sortBy';
import startCase from 'lodash/startCase';
import throttle from 'lodash/throttle';
import transform from 'lodash/transform';
import uniqBy from 'lodash/uniqBy';

export default {
  clone,
  cloneDeep,
  debounce,
  get,
  has,
  identity,
  intersection,
  isArray,
  isBoolean,
  isEmpty,
  isEqual,
  isNil,
  isNumber,
  isObject,
  isString,
  map,
  merge,
  omit,
  omitBy,
  partialRight,
  pick,
  pickBy,
  random,
  range,
  reduce,
  set,
  sortBy,
  startCase,
  throttle,
  transform,
  uniqBy,
  differenceOf
};

function differenceOf(v, b) {
  return transform(v, function getDiff(result, value, key) {
    if (!isEqual(value, b[key])) {
      result[key] = isObject(value) && isObject(b[key]) && !(Array.isArray(value) || Array.isArray(b[key])) ? differenceOf(value, b[key]) : value;
    }
  });
}
