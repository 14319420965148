import capitalize from './capitalize';
import { getWords } from './i18n/tools';

const isNumberOrString = (v) => ['number', 'string'].some((t) => t === typeof v);

const parseCount = (count, locale) => {
  let countValue, countText;

  if (!count) {
    countValue = 0;
    countText = '';
  } else if (isNumberOrString(count)) {
    countValue = countText = count;
  } else {
    const countEntity = count.count;
    if (isNumberOrString(countEntity)) {
      countValue = countText = countEntity;
    } else {
      let countTextEntity;
      [countValue, countTextEntity] = countEntity;
      if (!countTextEntity) {
        countText = countValue;
      } else if (isNumberOrString(countTextEntity)) {
        countText = countTextEntity;
      } else {
        countText = countTextEntity[locale];
      }
    }
  }
  if (!countText) countText = countValue;
  countValue = parseInt(countValue);

  return { countValue, countText };
};

const parseCapitalization = (capitalization, locale) => {
  if (!capitalization) return null;
  if (typeof capitalization === 'string') return capitalization;
  return capitalization[locale];
};

const addCommon = (value) => (value.match(/\S\.\S/) ? value : `common.${value}`);

const makeTT = (instance) => {
  return (value, count, capitalization) => {
    const { locale } = instance;
    if (value.includes(',,')) {
      const [v, i] = getWords(value, ',,');
      const idx = parseInt(i);
      return capitalize(getWords(instance.t(addCommon(v)), '|')[idx], parseCapitalization(locale));
    }
    const { countValue, countText } = parseCount(count, locale);

    const pluralized = instance.tc(addCommon(value), countValue, { count: countText });
    return capitalize(pluralized, parseCapitalization(capitalization, locale));
  };
};

const makeTTArray = (instance) => {
  const tt = makeTT(instance);

  return (value, count, capitalization) => {
    const { locale } = instance;

    const valueArray = getWords(value, '|');
    let countArray;
    if (count === undefined) {
      countArray = [];
    } else if (isNumberOrString(count)) {
      countArray = valueArray.map(() => ({ count: [parseInt(count), count] }));
    } else if (Array.isArray(count)) {
      countArray = count.map((c) => ({ count: [parseInt(c), c] }));
    } else {
      const countValue = count.count;
      if (countValue === undefined) return null;
      if (isNumberOrString(countValue)) {
        countArray = valueArray.map(() => ({ count: [parseInt(countValue), countValue] }));
      } else {
        const [cv0 = [], cv1 = cv0] = countValue;
        if (isNumberOrString(cv0)) {
          countArray = valueArray.map(() => ({ count: [parseInt(cv0), isNumberOrString(cv1) ? cv1 : cv1[locale]] }));
        } else if (Array.isArray(cv0)) {
          countArray = cv0.map((el, i) => ({
            count: [parseInt(el), isNumberOrString(cv1[i]) ? cv1[i] : cv1[i] ? cv1[i][locale] : el]
          }));
        }
      }
    }
    return capitalize(valueArray.map((el, i) => tt(el, countArray[i])).join(' '), parseCapitalization(capitalization, locale));
  };
};

export default makeTTArray;
