import _ from '@/apps/common/lodash';
import { parse, stringify } from 'qs';
import { identifyFilterFieldValue } from './identity';

const FilterFieldPredicate = Boolean;
const FilterStringifierConfig = { sort: compareStringsAsc };
const StringifiedFilterParsingConfig = { arrayLimit: 100 };

const stringifiedFilterKey = (route) => `${route}.filter.current`;

export function stringifyFilter(filter, getFilterFieldMeta) {
  const truthyValuesFilter = selectSituableFilterValues(filter, getFilterFieldMeta);
  return stringifyFilterValues(truthyValuesFilter);
}

export function parseStringifiedFilter(stringifiedFilter, getFilterFieldMeta) {
  const intermediateFilter = createIntermediateFilterFromString(stringifiedFilter);
  return createFilterFromIntermediate(intermediateFilter, getFilterFieldMeta);
}

export function normalizeParsedFilter(parsedFilter, initialFilter) {
  const initialFilterCopy = _.cloneDeep(initialFilter);
  return Object.assign(initialFilterCopy, parsedFilter);
}

export function getLocalStorageStringifiedFilter(route) {
  return localStorage.getItem(stringifiedFilterKey(route));
}

export function setLocalStorageStringifiedFilter(route, stringifiedFilter) {
  localStorage.setItem(stringifiedFilterKey(route), stringifiedFilter);
}

function createIntermediateFilterFromString(stringifiedFilter) {
  return parse(stringifiedFilter, StringifiedFilterParsingConfig);
}

function createFilterFromIntermediate(filter, getFilterFieldMeta) {
  return _.transform(filter, (filter, fieldValue, fieldName) => {
    const fieldMeta = getFilterFieldMeta(fieldName);
    if (fieldMeta.querystr) {
      _.set(filter, fieldName, identifyFilterFieldValue(fieldValue, fieldMeta));
    }
  });
}

function compareStringsAsc(stringA, stringB) {
  return stringA.localeCompare(stringB);
}

function selectSituableFilterValues(filter, getFilterFieldMeta) {
  return _.pickBy(filter, (value, key) => {
    const { querystr } = getFilterFieldMeta(key);
    return querystr && FilterFieldPredicate(value);
  });
}

function stringifyFilterValues(truthyValuesFilter) {
  return stringify(truthyValuesFilter, FilterStringifierConfig);
}
