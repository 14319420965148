import _ from '@/apps/common/lodash';
import { createVideoStream, disposeVideoStream, recordVideo } from './media-devices-helpers';

const TokenRenewalAttempts = 1;
const TokenRenewalDurationInMs = 2e3;
const TokenRenewalVideoLengthInMs = 15e2;

export const TokenState = {
  Valid: 'Valid',
  Expired: 'Expired',
  ExpiresSoon: 'ExpiresSoon'
};

export function detectTokenState(timestamp, expiresAt, intervalInMs, durationInMs) {
  const interval = expiresAt - timestamp;

  if (interval > intervalInMs + durationInMs) {
    return TokenState.Valid;
  }

  if (interval < durationInMs) {
    return TokenState.Expired;
  }

  return TokenState.ExpiresSoon;
}

export function recordTokenRenewalVideo() {
  return createAutoclosableVideoStream((stream) => {
    return recordVideo(stream, TokenRenewalVideoLengthInMs);
  });
}

export function computeSessionMonitorConfig(state) {
  const config = getRawConfig(state);
  const attempts = getTokenRenewalAttempts(config);
  const validityInMs = getTokenValidityInMs(config);
  const intervalInMs = getTokenRenewalIntervalInMs(config);
  const durationInMs = getTokenRenewalTotalDurationInMs(attempts);
  const expiresAt = getTokenExpirationTimestamp(state.app, validityInMs);
  const enabled = isTokenRenewalEnabled(config, intervalInMs);

  return { enabled, attempts, expiresAt, intervalInMs, durationInMs };
}

function getRawConfig(state) {
  return _.isObject(state.config?.auth) ? state.config.auth : {};
}

function isTokenRenewalEnabled(config, intervalInMs) {
  return intervalInMs > 0 && getValue(_.isBoolean, config.NEED_SESSION_RENEW, false);
}

function getTokenRenewalAttempts(config) {
  return getNumber(config.MAXIMUM_RENEW_ATTEMPTS, TokenRenewalAttempts);
}

function getTokenRenewalIntervalInMs(config) {
  return getNumber(config.RENEW_SESSION_INTERVAL, 0) * 1000;
}

function getTokenRenewalTotalDurationInMs(attempts) {
  return (TokenRenewalVideoLengthInMs + TokenRenewalDurationInMs) * attempts;
}

function getTokenExpirationTimestamp(app, validityInMs) {
  const timestamp = getNumber(app.timestamp, 0);
  return timestamp && timestamp + validityInMs;
}

function getTokenValidityInMs(config) {
  return getNumber(config.MAXIMUM_SESSION_LENGTH, 0) * 1000;
}

function getNumber(candidate, defaultValue) {
  return Math.max(getValue(_.isNumber, candidate, defaultValue), defaultValue);
}

function getValue(predicate, candidate, defaultValue) {
  return predicate(candidate) ? candidate : defaultValue;
}

function createAutoclosableVideoStream(handler) {
  let stream = null;
  return createVideoStream()
    .then((result) => handler((stream = result)))
    .finally(() => stream && disposeVideoStream(stream));
}
