export default {
  d: 'd',
  h: 'h',
  m: 'm',
  s: 's',
  second: '{count} second | {count} seconds',
  minute: '{count} minute | {count} minutes',
  hour: '{count} hour | {count} hours',
  day: '{count} day | {count} days',
  month: '{count} month | {count} months',
  year: '{count} year | {count} years',
  mon: 'mon',
  tue: 'tue',
  wed: 'wed',
  thu: 'thu',
  fri: 'fri',
  sat: 'sat',
  sun: 'sun',
  monday: 'monday',
  tuesday: 'tuesday',
  wednesday: 'wednesday',
  thursday: 'thursday',
  friday: 'friday',
  saturday: 'saturday',
  sunday: 'sunday'
};
