function connectToPuppeteerWebSocket({ state, getters, dispatch }) {
  state.p_server.connects++;

  if (window.p_ws) {
    window.p_ws.close();
    window.p_ws = null;
  }

  if (!state.app.token) {
    console.error('[p_ws] Try to connect without authorization');
    return;
  }

  let ws = new WebSocket(getters.wsPuppeteerServerUrl);
  window.p_ws = ws;

  ws.onerror = (e) => {
    console.warn('[ws:error]', e);
  };

  ws.onclose = () => {
    ws.onopen = ws.onclose = ws.onmessage = null;
    window.p_ws = null;
    state.p_server.connected = false;
    state.p_server.time = null;
    state.p_server.attempts++;
    state.app.token && setTimeout(() => dispatch('connectToPuppeteerWebSocket'), 1000);
  };

  ws.onopen = () => {
    state.p_server.connected = true;
    state.p_server.time = new Date();
    state.p_server.attempts = 0;

    ws.send(JSON.stringify({ type: 'auth', data: { token: state.app.token } })); // ffsecurity >= 4.1
    ws.send(JSON.stringify({ token: state.app.token })); // ffsecurity < 4.1
    ws.onmessage = (e) => dispatch('wsPuppeteerMessageHandler', e);
  };
}

function disconnectPuppeteerWebSocket({ state, getters, dispatch }) {
  if (window.p_ws) {
    window.p_ws.close();
    window.p_ws = null;
  }
}

export { connectToPuppeteerWebSocket, disconnectPuppeteerWebSocket };
