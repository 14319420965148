<template>
  <div class="header__back-button">
    <el-button name="back-btn" type="info" :plain="true" @click="backHandler">{{ $tf('back') }}</el-button>
  </div>
</template>
<script>
import Vue from 'vue';
import Component from 'vue-class-component';

@Component({
  name: 'HeaderBackButton'
})
export default class HeaderBackButton extends Vue {
  backHandler() {
    this.$router.back();
  }
}
</script>
<style lang="stylus">
.header__back-button:not(:empty) {
  align-self: center;
  margin-right: 1rem;
  flex: 0 0;
}
</style>
