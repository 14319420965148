import { capitalizeWord, getWords } from './i18n/tools';

const CapitalizeOptions = Object.freeze({
  each: 'each',
  first: 'first'
});

const capitalize = (sentence, rule) => {
  const words = getWords(sentence);
  switch (rule) {
    case CapitalizeOptions.each:
      return words.map(capitalizeWord).join(' ');
    case CapitalizeOptions.first:
      return words.map((w, i) => (i ? w : capitalizeWord(w))).join(' ');
    default:
      return sentence;
  }
};

export default capitalize;
