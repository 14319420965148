<template>
  <div id="root" class="loading-container">
    <i class="el-icon-loading loading-icon"></i>
    <h3>Loading...</h3>
  </div>
</template>
<script>
import Vue from 'vue';
import Component from 'vue-class-component';

@Component({
  name: 'Loading'
})
export default class Loading extends Vue {}
</script>

<style lang="stylus">
.loading-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .loading-icon {
    font-size: 2rem;
  }

  .h1 {
    margin-top: 1rem;
  }
}
</style>
