import _ from '@/apps/common/lodash';
import getCrud from '../get.crud';

const emptyFilter = {
    user_lists: []
  },
  emptyItem = {
    user: '',
    source_photo: ''
  },
  Name = 'user-face',
  Action = require('../action.names').default.UserFace;

export default {
  state: {
    page: 0,
    next_page: null,
    prev_page: null,
    items: [],
    item: {
      current: _.cloneDeep(emptyItem),
      empty: emptyItem
    },
    filter: {
      empty: emptyFilter,
      current: _.cloneDeep(emptyFilter)
    },
    Action
  },
  actions: Object.assign({}, getCrud(Name))
};
