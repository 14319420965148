const CalibrationStatuses = {
  NOT_CALIBRATED: 'NOT_CALIBRATED',
  AWAITING_SYNC: 'AWAITING_SYNC',
  COLLECTING_CALIBRATION_DATA: 'COLLECTING_CALIBRATION_DATA',
  AWAITING_PROXIMITY_MODEL: 'AWAITING_PROXIMITY_MODEL',
  CALIBRATED: 'CALIBRATED',
  CALIBRATION_FAILED: 'CALIBRATION_FAILED'
};

const CalibrationStatusesI18n = {
  NOT_CALIBRATED: 'calibration_status__not_calibrated',
  AWAITING_SYNC: 'calibration_status__awaiting_sync',
  COLLECTING_CALIBRATION_DATA: 'calibration_status__collecting_calibration_data',
  AWAITING_PROXIMITY_MODEL: 'calibration_status__awaiting_proximity_model',
  CALIBRATED: 'calibration_status__calibrated',
  CALIBRATION_FAILED: 'calibration_status__calibration_failed'
};

export { CalibrationStatuses, CalibrationStatusesI18n };
