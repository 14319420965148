import _ from '@/apps/common/lodash';
import ActionsNames from '../action.names';
import getCrud from '../get.crud';
import { PictureRenderingMode, ReportStatus } from './enums.reports';

const EmptyReport = {
  id: 0,
  status: ReportStatus.PROCESSING,
  modified_date: '',
  size: null,
  report_url: null,
  records_number: null,
  filters: {},
  picture_rendering_mode: PictureRenderingMode.PREVIEW
};

const EmptyReportFilter = {
  limit: 20
};

const Name = 'reports';

export default {
  state: {
    page: '',
    loading: false,
    next_page: null,
    prev_page: [],
    items: [],
    item: {
      empty: EmptyReport,
      current: _.cloneDeep(EmptyReport)
    },
    filter: {
      empty: EmptyReportFilter,
      current: _.cloneDeep(EmptyReportFilter)
    },
    Action: ActionsNames.Reports
  },
  actions: { ...getCrud(Name), restartReports }
};

function restartReports({ state, dispatch }, payload) {
  return dispatch('requestApi', { model: Name, subaction: 'restart', id: payload.id, method: 'post' });
}
