// import qs from 'qs'
import _ from '@/apps/common/lodash';
import getCrud from '@/store/get.crud';

const emptyFilter = {},
  Name = 'dicts',
  Action = require('../action.names').default.Dicts;

export default {
  state: {
    page: 0,
    next_page: null,
    prev_page: null,
    items: {},
    loading: false,
    loaded_date: null,
    filter: {
      empty: emptyFilter,
      current: _.cloneDeep(emptyFilter)
    },
    Action
  },
  actions: Object.assign(getCrud(Name))
};
