<template>
  <div class="filter-form-clear-button">
    <slot></slot>
    <div class="filter-form-clear-button__button" v-if="visible">
      <el-button @click="dispatchClearEvent" icon="el-icon-close" size="mini" circle />
    </div>
  </div>
</template>

<script>
import { Component, Vue } from 'vue-property-decorator';

@Component({
  props: {
    visible: { type: Boolean, required: true }
  }
})
export default class FilterFormClearButton extends Vue {
  dispatchClearEvent() {
    this.$emit('clear');
  }
}
</script>

<style lang="stylus">
.filter-form-clear-button {
  position: relative;

  &__button {
    top: -2rem;
    right: 0.25rem;
    position: absolute;
  }
}
</style>
