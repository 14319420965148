<template>
  <filter-form-clear-button :visible="!isFieldClear" @clear="clearField">
    <el-date-picker @input="handleInputEvent" :placeholder="placeholder" :clearable="false" :value="model" align="right" :type="type" />
  </filter-form-clear-button>
</template>

<script>
import { setDateEndOfDayTime } from '@/store/kyc/kyc.helpers.js';
import { Component } from 'vue-property-decorator';
import FilterFormClearableComponent from './filter-form-component-clearable';

@Component({
  props: {
    type: { type: String, default: 'date' },
    endOfDay: { type: Boolean, default: false }
  }
})
export default class FilterFormComponentDateTime extends FilterFormClearableComponent {
  handleInputEvent(value) {
    this.model = value && this.endOfDay ? setDateEndOfDayTime(value) : value;
    this.clearLinkedFields();
  }
}
</script>
