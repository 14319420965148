const EndOfDayHours = 23;
const EndOfDayMinutes = 59;
const EndOfDaySeconds = 59;
const EndOfDayMilliseconds = 0;
const FullDayInMilliseconds = 86399e3;
const TwoWeeksInMilliseconds = 12096e5;

export function setFilterTodayAndTwoWeeksAgoDates(filter) {
  const today = setDateEndOfDayTime(new Date());
  filter.created_date_lte = today.toISOString();
  today.setTime(today - TwoWeeksInMilliseconds - FullDayInMilliseconds);
  filter.created_date_gte = today.toISOString();
}

export function setDateEndOfDayTime(date) {
  return date.setHours(EndOfDayHours, EndOfDayMinutes, EndOfDaySeconds, EndOfDayMilliseconds), date;
}
