const Weekdays = {
  Mon: 'Mon',
  Tue: 'Tue',
  Wed: 'Wed',
  Thu: 'Thu',
  Fri: 'Fri',
  Sat: 'Sat',
  Sun: 'Sun'
};

const WeekdaysShortI18n = {
  Mon: 'mon',
  Tue: 'tue',
  Wed: 'wed',
  Thu: 'thu',
  Fri: 'fri',
  Sat: 'sat',
  Sun: 'sun'
};

const WeekdaysI18n = {
  Mon: 'monday',
  Tue: 'tuesday',
  Wed: 'wednesday',
  Thu: 'thursday',
  Fri: 'friday',
  Sat: 'saturday',
  Sun: 'sunday'
};

const WeekdaysWeights = Object.freeze({
  Mon: 1,
  Tue: 2,
  Wed: 3,
  Thu: 4,
  Fri: 5,
  Sat: 6,
  Sun: 7
});

export { Weekdays, WeekdaysI18n, WeekdaysShortI18n, WeekdaysWeights, compareWeekdayByWeight };

function compareWeekdayByWeight(weekdayA: string, weekdayB: string) {
  return getWeekdayWeight(weekdayA) - getWeekdayWeight(weekdayB);
}

function getWeekdayWeight(weekday: string) {
  return WeekdaysWeights[weekday];
}
