<template>
  <div>
    <el-form v-bind="$form.getFormProps($options, {}, {})" v-loading="loading">
      <input name="file" ref="file" type="file" @change="selectFile" style="display: none" />
      <el-button @click="selectHandler">{{ $tf('select_file') }}</el-button>
      <el-form-item v-if="detectedItemsCount" :label="$tf('photo')">
        <object-selector v-model="detectState.selectedIndex" class="full" :image="detectState.image" :items="detectState.items" />
      </el-form-item>
    </el-form>
  </div>
</template>
<script>
import Component from 'vue-class-component';
import { ObjectsType } from '@/store/objects/get.module';
import ObjectSelector from '../../../objects/detect/object.selector';
import { Watch } from 'vue-property-decorator';
import _ from '@/apps/common/lodash';

const EmptyDetectState = {
  selectedIndex: 0,
  items: [],
  image: {
    url: null
  }
};

@Component({
  name: 'FileObjectsSelector',
  components: { ObjectSelector },
  props: {
    objects: {
      type: String,
      default: ObjectsType.Faces
    },
    filter: {
      type: Object
    },
    features: {
      type: Object
    }
  }
})
export default class FileObjectsSelector extends Component {
  loading = false;
  detectState = _.cloneDeep(EmptyDetectState);

  get detectedItemsCount() {
    return this.detectState?.items?.length ?? 0;
  }

  get detectionValue() {
    const r = this.detectState.items[this.detectState.selectedIndex]?.id;
    return r ? `detection:${r}` : '';
  }

  @Watch('detectState.selectedIndex')
  selectedIndexHandler(v, p) {
    if (this.filter) this.filter.looks_like = `detection:${this.detectState.items[v].id}`;
    this.$emit('change', {
      index: v,
      detection: this.detectionValue
    });
  }

  mounted() {}

  destroy() {}

  selectHandler() {
    this.$refs.file.click();
  }

  reset() {
    this.loading = false;
    this.detectState = _.cloneDeep(EmptyDetectState);
  }

  async selectFile(e) {
    let file = e.target.files && e.target.files[0];
    e.target.value = null;
    try {
      this.$emit('change', { index: 0, detection: '' });
      this.loading = true;
      this.detectState = await this.$store.dispatch('getDetectObjectsState', { file: file, objects: this.objects, features: this.features });
      this.selectedIndexHandler(0, 0);
    } catch (e) {
      this.$notify({ duration: 0, message: this.$createElement('message-box', { props: { e } }) });
    } finally {
      this.loading = false;
    }
  }
}
</script>

<style lang="stylus"></style>
