<template>
  <filter-form-clear-button :visible="!isFieldClear" @clear="clearField">
    <el-select :multiple-limit="multipleLimit" @change="clearLinkedFields" :placeholder="placeholder" v-model="model" multiple>
      <el-option v-for="item in items" :key="item.id" :value="item.id" :label="item.name">
        <filter-form-component-watch-lists-item :color="item.color" :name="item.name" />
      </el-option>
    </el-select>
  </filter-form-clear-button>
</template>

<script>
import _ from '@/apps/common/lodash';
import { Component } from 'vue-property-decorator';
import FilterFormClearableComponent from './filter-form-component-clearable';
import FilterFormComponentWatchListsItem from './filter-form-component-watch-lists-item';

@Component({
  props: {
    itemsPath: { type: String, required: true },
    multipleLimit: { type: Number, default: 10 },
    excludeUnmatched: { type: Boolean, default: false }
  },
  components: { FilterFormComponentWatchListsItem }
})
export default class FilterFormComponentWatchLists extends FilterFormClearableComponent {
  get items() {
    const items = this.fetchWatchListsByPath();
    return this.excludeUnmatched ? excludeUnmatchedWatchList(items) : items;
  }

  fetchWatchListsByPath() {
    return _.get(this, this.itemsPath).map(this.normalizeWatchList);
  }

  normalizeWatchList(item) {
    return { ...item, name: this.$filters.shortString(item.name) };
  }
}

function excludeUnmatchedWatchList(items) {
  return items.filter((item) => item.id > 0);
}
</script>
