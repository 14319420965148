





import { Prop, Component, Vue } from 'vue-property-decorator';
import { TableComponentNames } from '@/components/tables/components/names';

@Component({
  name: TableComponentNames.AuthSessionButtons
})
export default class TableGeneratorAuthSessionButtons extends Vue {
  @Prop({ required: true })
  item!: any;

  get hasBlockButton() {
    return this.$hasPermission('ffsecurity.add_deviceblacklistrecord') && this.item.status !== 'blocked';
  }

  click(type) {
    return this.$emit('action', { type, item: this.item });
  }
}
