












import Vue from 'vue';
import axios from 'axios';
import Component from 'vue-class-component';
import PolygonDrawTool from '@/components/polygon-draw/index.vue';
import { Prop } from 'vue-property-decorator';

@Component({
  name: 'camera-regions-item',
  components: {
    PolygonDrawTool: PolygonDrawTool as any
  }
})
export default class CameraRegionsItem extends Vue {
  @Prop({ type: Number, required: true })
  cameraId!: number;

  @Prop({ type: Array, required: true })
  value!: any[];

  loading = false;
  imageDataUrl: null | string = null;

  get points() {
    return this.value.find((v) => v.camera === this.cameraId)?.roi || [];
  }

  setPoints(v) {
    const value = this.value || [];
    let newValue = [...value.filter((v) => v.camera !== this.cameraId)];
    newValue.push({ camera: this.cameraId, roi: v || null });

    this.$emit('input', newValue);
    this.$emit('change', newValue);
  }

  getCameraScreenshotUrl() {
    return this.$store.state.config.server.url + `cameras/${this.cameraId}/screenshot/?width=-1&height=-1`;
  }

  mounted() {
    this.loadScreenshot();
  }

  loadScreenshot(force = false) {
    if (this.imageDataUrl && !force) return;
    this.loading = true;
    return axios({
      url: this.getCameraScreenshotUrl(),
      method: 'post',
      responseType: 'blob',
      headers: {
        Authorization: 'Token ' + encodeURIComponent(this.$store.state.app.token)
      }
    })
      .then((v) => {
        if (v.data && v.data.size) {
          this.imageDataUrl = URL.createObjectURL(v.data);
        } else {
          throw new Error('[image:loaded] empty data error');
        }
      })
      .catch((e) => {
        this.$notify({ duration: 0, message: this.$createElement('message-box', { props: { e: e } }) });
      })
      .finally(() => {
        this.loading = false;
      });
  }
}
