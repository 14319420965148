<template>
  <div
    class="menu-right-side flex-cell--static"
    :class="{
      'is-over': intersect,
      collapsed: $store.state.app.filter.collapsed
    }"
    @mousemove="mousemoveHandler"
    @mouseout="mouseoutHandler"
    @blur="mouseoutHandler"
  >
    <slot></slot>
    <div class="collapse-border" @click="switchCollapsed" />
    <div class="collapse-button--small" @click="switchCollapsed" />
    <div class="collapse-button" @click="switchCollapsed" />
  </div>
</template>

<script>
export default {
  name: 'right-container',
  data() {
    return {
      intersect: false
    };
  },
  mounted() {},
  methods: {
    switchCollapsed() {
      this.$store.state.app.filter.collapsed = !this.$store.state.app.filter.collapsed;
      this.$store.dispatch('syncToLocalStorage');
      setTimeout(() => {
        window.dispatchEvent(new Event('resize'));
      }, 500);
    },
    mousemoveHandler(e) {
      const clientRect = this.$el.getBoundingClientRect(),
        intersect = clientRect.left - e.pageX > -12;
      this.intersect = intersect;
    },
    mouseoutHandler(e) {
      this.intersect = false;
    }
  }
};
</script>
