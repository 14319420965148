<template>
  <div>
    <div>
      <h1>Icon buttons / C иконками</h1>
      <h2>Button with icons</h2>
      <el-button type="primary" v-for="(icon, key) in icons" :icon="'el-icon-' + icon" :key="key">{{ icon }}</el-button>

      <h2>Only icons</h2>
      <el-button type="primary" v-for="(icon, key) in icons" :icon="'el-icon-' + icon" :key="key"></el-button>

      <h4>Different types of button</h4>
      <el-button icon="el-icon-check"></el-button>
      <el-button type="primary" icon="el-icon-check"></el-button>
      <el-button type="text" icon="el-icon-check"></el-button>

      <h4>Different types and sizes of button</h4>
      <div v-for="type in types" :key="type">
        <el-button icon="el-icon-check" :type="type" :key="size" v-for="size in sizes" :size="size"></el-button>
      </div>
    </div>

    <div>
      <h4>Font Awesome icons</h4>

      <div>
        <el-button type="primary" v-for="size in sizes" :size="size" :key="size">
          <i class="fa fa-table" aria-hidden="true"></i>
          <span>Use text label</span>
        </el-button>
      </div>
      <div>
        <el-button type="primary" v-for="size in sizes" :size="size" :key="size">
          <i class="fa fa-th" aria-hidden="true"></i>
        </el-button>
      </div>
      <div>
        <el-button type="primary" v-for="size in sizes" :size="size" :key="size">
          <i class="fa fa-th-large" aria-hidden="true"></i>
        </el-button>
      </div>
      <div>
        <el-button v-for="size in sizes" :size="size" :key="size">
          <i class="fa fa-align-justify" aria-hidden="true"></i>
        </el-button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'guide-icons',
  props: ['included'],
  data() {
    return {
      sizes: ['large', '', 'small', 'mini'],
      types: ['', 'primary', 'warning', 'text'],
      icons: [
        'arrow-down',
        'arrow-left',
        'arrow-right',
        'arrow-up',
        'caret-bottom',
        'caret-left',
        'caret-right',
        'caret-top',
        'check',
        'circle-check',
        'circle-close',
        'circle-cross',
        'close',
        'upload',
        'd-arrow-left',
        'd-arrow-right',
        'd-caret',
        'date',
        'delete',
        'document',
        'edit',
        'information',
        'loading',
        'menu',
        'message',
        'minus',
        'more',
        'picture',
        'plus',
        'search',
        'setting',
        'share',
        'star-off',
        'star-on',
        'time',
        'warning',
        'delete2',
        'upload2',
        'view'
      ],
      msg: 'Welcome to Your Vue.js App'
    };
  }
};
</script>
