<template>
  <el-checkbox-group class="base-form-component-checkbox-group" v-model="model">
    <el-checkbox v-for="item of items" :key="item.label" :label="item.value" @change="clearLinkedFields">{{ computeItemLabel(item) }}</el-checkbox>
  </el-checkbox-group>
</template>

<script>
import { Component } from 'vue-property-decorator';
import BaseFormComponent from './component';

@Component({
  props: { items: { type: Array, required: true } }
})
export default class BaseFormComponentCheckboxGroup extends BaseFormComponent {
  computeItemLabel({ i18nLabel, label }) {
    return (i18nLabel && this.$tf(i18nLabel)) ?? label;
  }
}
</script>

<style lang="stylus">
.base-form-component-checkbox-group {
  display: flex;
  flex-wrap: wrap;

  .el-checkbox {
    flex: 0 0 50%;
    margin: 0 !important;
  }
}
</style>
