export default {
  state: {
    layout: 6,
    episodes: false,
    wallType: 'video',
    state: 'none',
    draggable: true,
    draggingItem: null,
    items: {}
  }
};
