function checkStringAndParseJson(v) {
  return typeof v === 'string' ? JSON.parse(v) : v;
}

function wsPuppeteerMessageHandler({ state, dispatch }, e) {
  const module = state.puppeteer_remote_monitoring_events;
  let message = JSON.parse(e.data),
    event = null;

  if (!message) return;

  if (message.type === 'events_acknowledged') {
    // module.commandItems.push(message)
    return;
  }

  if (message.type === 'monitoring_event_created' && module.playing) {
    event = checkStringAndParseJson(message.data);
    insertEvent(event);
  }

  if (message.type === 'monitoring_event_updated' && message.data) {
    const updatedItem = checkStringAndParseJson(message.data),
      storedItem = module.items.find((v) => v.id === updatedItem.id);
    if (storedItem) {
      Object.assign(storedItem, updatedItem);
    }
  }

  if (message.type === 'unacknowledged') {
    module.unacknowledged = message.data.all;
    module.notify = message.data.notify;
  }

  function insertEvent(event) {
    dispatch('satisfy_puppeteer_remote_monitoring_event', event).then((v) => {
      if (v) {
        let sameItem = module.items.find((i) => i.id === event.id);
        if (!sameItem) {
          module.items = [].concat(event, module.items).slice(0, module.filter.current.limit);
        }
      } else {
        console.warn('Event is not satisfied to filters ', state.faces_events.filter.current, event);
      }
    });
  }
}

export default wsPuppeteerMessageHandler;
