<template>
  <div class="filter-form-component-watch-lists-item">
    <div class="filter-form-component-watch-lists-item__color" :style="colorTagStyle"></div>
    <div class="filter-form-component-watch-lists-item__name" v-text="name"></div>
  </div>
</template>

<script>
import { Component, Vue } from 'vue-property-decorator';

@Component({
  props: {
    name: { type: String, required: true },
    color: { type: String, required: true }
  }
})
export default class FilterFormComponentWatchListsItem extends Vue {
  get colorTagStyle() {
    return { backgroundColor: `#${this.color}` };
  }
}
</script>

<style lang="stylus">
.filter-form-component-watch-lists-item {
  display: flex;
  align-items: center;

  &__color {
    border-radius: 50%;
    margin-right: 1rem;
    height: 1rem;
    width: 1rem;
    flex: none;
  }

  &__name {
    flex: 1;
  }
}
</style>
