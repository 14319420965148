




import { Component, Vue } from 'vue-property-decorator';
import FilterForm from '@/components/filter-form/filter-form.vue';
import { authSessionsModule } from '@/store/index';
import { AuthSessionsModule } from '@/store/sessions/sessions';

@Component({
  name: 'auth-sessions-filter',
  components: { FilterForm }
})
export default class AreasFilter extends Vue {
  get state(): AuthSessionsModule {
    return authSessionsModule;
  }
}
