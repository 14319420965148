export default {
  d: 'д',
  h: 'ч',
  m: 'м',
  s: 'с',
  second: '{count} секунда | {count} секунды | {count} секунд',
  minute: '{count} минута | {count} минуты | {count} минут',
  hour: '{count} час | {count} часа | {count} часов',
  day: '{count} день | {count} дня | {count} дней',
  month: '{count} месяц | {count} месяца | {count} месяцев',
  year: '{count} год | {count} года | {count} лет',
  mon: 'пн',
  tue: 'вт',
  wed: 'ср',
  thu: 'чт',
  fri: 'пт',
  sat: 'сб',
  sun: 'вс',
  monday: 'понедельник',
  tuesday: 'вторник',
  wednesday: 'среда',
  thursday: 'четверг',
  friday: 'пятница',
  saturday: 'суббота',
  sunday: 'воскресенье'
};
