import getCrud from '../get.crud';

const Name = 'settings';

export default {
  state: {
    filter: {
      empty: {},
      current: {}
    },
    loading: false,
    items: {
      face_confidence_threshold: 0.745,
      body_confidence_threshold: 0.745,
      car_confidence_threshold: 0.745,
      minimum_face_quality: 0.45,
      minimum_car_quality: 0.45,
      minimum_body_quality: 0.45,
      face_events_max_matched_age: 30,
      face_events_max_unmatched_age: 30,
      face_events_max_fullframe_matched_age: 30,
      face_events_max_fullframe_unmatched_age: 30,
      car_events_max_matched_age: 30,
      car_events_max_unmatched_age: 30,
      car_events_max_fullframe_matched_age: 30,
      car_events_max_fullframe_unmatched_age: 30,
      body_events_max_matched_age: 30,
      body_events_max_unmatched_age: 30,
      body_events_max_fullframe_matched_age: 30,
      body_events_max_fullframe_unmatched_age: 30,
      liveness_threshold: 0.85,
      thumbnail_jpeg_quality: 75
    }
  },
  actions: Object.assign({}, getCrud(Name))
};
