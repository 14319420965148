import store from '../store';
const LoginRoute = { name: 'login', replace: true };

export function createUserLoginNavigationGuard(isUserLoggedIn) {
  const strategy = createNavigationStrategyFactory();
  return (to, from, next) => strategy(to, from, isUserLoggedIn())(next);
}

function createNavigationStrategyFactory() {
  let stashedFullPath = null;

  return (to, from, loggedIn) => {
    return (loggedIn ? selectStrategyWhenUserIsLoggedIn : selectStrategyWhenUserIsLoggedOut)(to, from);
  };

  function selectStrategyWhenUserIsLoggedIn(to, from) {
    if (isLoginRoute(from) && stashedFullPath) {
      return createRestoreStashedStrategy();
    }
    if (isLoginRoute(to)) {
      return isInitialRoute(from) ? replaceWithDefaultRoute : stopNavigation;
    }
    return continueNavigation;
  }

  function selectStrategyWhenUserIsLoggedOut(to, from) {
    if (isLoginRoute(from)) {
      return stopNavigation;
    }
    if (isLoginRoute(to)) {
      return continueNavigation;
    }
    return createStashAndReplaceWithLoginRouteStrategy(to.fullPath);
  }

  function stopNavigation(next) {
    next(false);
  }

  function continueNavigation(next) {
    next();
  }

  function createRestoreStashedStrategy() {
    return (next) => {
      const route = { path: stashedFullPath, replace: true };
      stashedFullPath = null;
      next(route);
    };
  }

  function createStashAndReplaceWithLoginRouteStrategy(fullPath) {
    return (next) => {
      stashedFullPath = fullPath;
      next(LoginRoute);
    };
  }

  function replaceWithDefaultRoute(next) {
    next(store.getters.defaultRoutePath);
  }
}

function isInitialRoute({ name, fullPath }) {
  return name === null && fullPath === '/';
}

function isLoginRoute({ name }) {
  return name === LoginRoute.name;
}
