<template>
  <div class="content">
    <h4>Dialogs examples</h4>

    <div class="container" :key="size" v-for="size in sizes">
      <el-dialog :title="`Dialog '${size}' size`" :modal="false" :visible="true" :size="size">
        <span>Content here</span>

        <span slot="footer" class="dialog-footer">
          <el-button>Cancel</el-button>
          <el-button type="primary">Confirm</el-button>
        </span>
      </el-dialog>
    </div>
  </div>
</template>

<script>
export default {
  name: 'dialogs',
  methods: {
    upper: (v) => v.toString().toUpperCase()
  },
  data() {
    return {
      sizes: ['full', 'large', 'small', 'tiny'],
      visible: {
        tip: true,
        next: true
      },
      msg: 'Welcome to Your Vue.js App'
    };
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style type="text/stylus" scoped>
.container {
  position: relative;
  min-height: 20rem;
  margin-bottom: 1rem;
}

.container .el-dialog__wrapper {
  position: absolute;
}
</style>
