import { render, staticRenderFns } from "./forms.vue?vue&type=template&id=3ef0f818&scoped=true&"
import script from "./forms.vue?vue&type=script&lang=js&"
export * from "./forms.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../../tmp/ffsecurity-ui/node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3ef0f818",
  null
  
)

export default component.exports