import _ from '@/apps/common/lodash';
import getCrud from '../get.crud';
import { actions } from './actions.permissions';

const emptyFilter = {},
  emptyItem = {
    id: null,
    name: '',
    code_name: '',
    permission_id: ''
  },
  Name = 'permissions',
  Action = require('../action.names').default.Permissions;

export default {
  state: {
    page: 0,
    next_page: null,
    prev_page: null,
    items: [],
    item: {
      current: _.cloneDeep(emptyItem),
      empty: emptyItem
    },
    filter: {
      empty: emptyFilter,
      current: _.cloneDeep(emptyFilter)
    },
    Action
  },
  actions: { ...actions, ...getCrud(Name) }
};
