function connectToWebSocket({ state, getters, dispatch }) {
  state.server.connects++;

  if (window.ws) {
    window.ws.close();
    window.ws = null;
  }

  if (!state.app.token) {
    console.error('[ws] Try to connect without authorization');
    return;
  }

  let ws = new WebSocket(getters.wsServerUrl);
  window.ws = ws;

  ws.onerror = (e) => {
    console.warn('[ws:error]', e);
  };

  ws.onclose = () => {
    ws.onopen = ws.onclose = ws.onmessage = null;
    window.ws = null;
    state.server.connected = false;
    state.server.time = null;
    state.server.attempts++;
    state.app.token && setTimeout(() => dispatch('connectToWebSocket'), 1000);
  };

  ws.onopen = () => {
    state.server.connected = true;
    state.server.time = new Date();
    state.server.attempts = 0;

    ws.send(JSON.stringify({ type: 'auth', data: { token: state.app.token, msg_groups: state.server.subscriptions } })); // ffsecurity >= 4.1
    ws.send(JSON.stringify({ token: state.app.token })); // ffsecurity < 4.1
    ws.onmessage = (e) => dispatch('wsMessageHandler', e);
  };
}

export default connectToWebSocket;
