





import Component from 'vue-class-component';
import Vue from 'vue';
import { TableComponentNames } from '@/components/tables/components/names';
import CameraScreenshot from '@/components/cameras/camera-screenshot.vue';
import { Prop } from 'vue-property-decorator';

@Component({
  name: TableComponentNames.CameraScreenshot,
  components: { CameraScreenshot }
})
export default class TableGeneratorCameraScreenshot extends Vue {
  @Prop({ required: true, type: Object })
  item!: any;

  @Prop({ default: 'id' })
  prop!: string;

  get value(): string | null {
    const propValue = this.item[this.prop],
      id = Array.isArray(propValue) ? propValue[0] : propValue;
    return id ? this.getScreenShotUrlById(id) : null;
  }

  getScreenShotUrlById(id: string | number): string {
    return this.$store.state.config.server.url + `cameras/${id}/screenshot/`;
  }
}
