<template>
  <div>
    <el-table :size="$vars.sizes.table || $vars.sizes.common" stripe :data="items" row-key="id" @row-click="(v) => socialClickHandler(v)" class="social-table">
      <el-table-column prop="uid" :label="$tf('id')" width="120"></el-table-column>
      <el-table-column prop="photo" :label="$tf('photo')" width="176">
        <template slot-scope="{ row }">
          <object-thumbnail :url="row.photo || ''" :frame="row.photo || ''" @preview="(v) => $store.dispatch('showImage', v)"></object-thumbnail>
        </template>
      </el-table-column>
      <el-table-column prop="name">
        <template slot-scope="{ row }">
          <a :href="row.profile" target="_blank">{{ row.name }} ({{ row.confidence | floatToPercent }})</a><br />
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>
<script>
import Component from 'vue-class-component';
import { ObjectsType } from '@/store/objects/get.module';

@Component({
  name: 'SocialSearchResults',
  props: {
    items: {
      type: Array,
      required: true
    },
    objects: {
      type: String,
      default: ObjectsType.Faces
    }
  }
})
export default class SocialSearchResults extends Component {
  socialClickHandler(v) {
    window.open(v.profile, '_blank');
  }
}
</script>

<style lang="stylus"></style>
